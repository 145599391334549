// src/components/Teachers/Progress/Progress.js

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataController } from '../../../contexts/datacontroller';
import { ref, update, onValue, runTransaction, off, set } from 'firebase/database';
import { rtdb } from '../../../firebase';
import XPProgressArc from './XPProgressArc'; // Import XPProgressArc
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported

const Progress = ({ show, handleClose, title, bees: initialBees }) => {
  const { activeClasses } = useDataController();
  const [bees, setBees] = useState(initialBees || []);
  const [selectedBee, setSelectedBee] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Define xpPerLevel and MAX_LEVEL
  const MAX_LEVEL = 100; // Example maximum level
  const xpPerLevel = Array(MAX_LEVEL).fill(0).map((_, index) => 100 * (index + 1)); // Example XP per level

  // Function to calculate level from XP
  const getLevelFromXP = (xp) => {
    if (xp < 0) return 0;
    let accumulatedXP = 0;
    let level = 0;

    while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= xp) {
      accumulatedXP += xpPerLevel[level];
      level++;
    }

    return level;
  };

  // State to hold average level
  const [averageLevel, setAverageLevel] = useState(0);

  // Function to calculate average level
  const calculateAverageLevel = () => {
    if (!bees || bees.length === 0) return 0;
    const totalLevels = bees.reduce((acc, bee) => acc + getLevelFromXP(bee.totalxp), 0);
    return totalLevels / bees.length;
  };

  // Update average level whenever bees data changes
  useEffect(() => {
    const avgLevel = calculateAverageLevel();
    setAverageLevel(avgLevel);
  }, [bees]);

  // Real-time listeners setup (existing code)
  useEffect(() => {
    const beeRefs = [];

    const handleDataUpdate = (snapshot, classId) => {
      const beeData = snapshot.val();
      if (beeData) {
        const updatedBees = Object.entries(beeData).map(([name, data]) => ({
          name,
          ...data,
          classId,
        }));
        setBees((prevBees) => {
          const beesMap = new Map(
            prevBees.map((bee) => [`${bee.classId}-${bee.name}`, bee])
          );
          updatedBees.forEach((bee) =>
            beesMap.set(`${bee.classId}-${bee.name}`, bee)
          );
          return Array.from(beesMap.values());
        });
      }
    };

    if (activeClasses && typeof activeClasses === 'object') {
      Object.entries(activeClasses).forEach(([classId, cls]) => {
        const beeRef = ref(rtdb, `activeclasses/${classId}/beeNames`);
        onValue(beeRef, (snapshot) => handleDataUpdate(snapshot, classId));
        beeRefs.push(beeRef);
      });
    }

    return () => {
      beeRefs.forEach((beeRef) => off(beeRef));
    };
  }, [activeClasses]);

  // Function to handle adding XP to all bees (existing code)
  const handleAddXPToAll = async () => {
    if (!bees || bees.length === 0) return;

    const updates = {};
    bees.forEach((bee) => {
      updates[`activeclasses/${bee.classId}/beeNames/${bee.name}/totalxp`] =
        bee.totalxp + 100;
    });

    try {
      await update(ref(rtdb), updates);
      console.log('Added 100 XP to all bees.');
    } catch (error) {
      console.error('Error adding XP to all bees:', error);
    }
  };

  // Function to handle opening the popup for a specific bee (existing code)
  const handleBeeClick = (bee) => {
    setSelectedBee(bee);
    setIsPopupOpen(true);
  };

  // Function to handle adding XP to a single bee (existing code)
  const handleAddXPSingleBee = async () => {
    if (!selectedBee) return;

    const beeXPPath = `activeclasses/${selectedBee.classId}/beeNames/${selectedBee.name}/totalxp`;
    const beeXPRef = ref(rtdb, beeXPPath);

    try {
      await runTransaction(beeXPRef, (currentXP) => {
        if (currentXP === null) {
          return 100;
        } else {
          return currentXP + 100;
        }
      });
      console.log(`Added 100 XP to ${selectedBee.name}.`);
      setIsPopupOpen(false);
    } catch (error) {
      console.error(`Error adding XP to ${selectedBee.name}:`, error);
    }
  };

  // Function to handle closing the popup (existing code)
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedBee(null);
  };

  const handleCloseModal = useCallback(() => {
    console.log('handleCloseModal called.');
    handleClose();
    console.log('Modal closed.');
  }, [handleClose]);

  // Callback to handle level-up from XPProgressArc
  const handleLevelUp = async (newLevel, xpRequired) => {
    if (!selectedBee) return;

    const beeXPPath = `activeclasses/${selectedBee.classId}/beeNames/${selectedBee.name}/totalxp`;
    const beeXPRef = ref(rtdb, beeXPPath);

    try {
      await runTransaction(beeXPRef, (currentXP) => {
        if (currentXP === null) {
          return 0; // Starting fresh if no XP exists
        } else {
          return currentXP - xpRequired;
        }
      });
      console.log(`${selectedBee.name} leveled up to level ${newLevel}!`);
    } catch (error) {
      console.error(`Error leveling up ${selectedBee.name}:`, error);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-11/12 max-w-4xl max-h-screen p-4 bg-white rounded-lg shadow-lg">
            {/* Top Bar with Centered Title and Close Button */}
            <div className="relative flex items-center justify-center w-full h-12 px-4 bg-transparent">
              <h2 className="text-xl font-semibold">{title}</h2>
              <button
                onClick={handleCloseModal}
                className="absolute text-2xl text-gray-500 top-3 right-4 hover:text-gray-700"
                aria-label="Close Modal"
                onMouseDown={(e) => e.stopPropagation()} // Prevent closing when clicking the button
              >
                <FaTimes />
              </button>
            </div>

            {/* Add XP Button */}
            <div className="flex justify-end my-4">
              <button
                onClick={handleAddXPToAll}
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
              >
                Add 100 XP to All Bees
              </button>
            </div>

            {/* Bees Grid */}
            <div className="grid grid-cols-2 gap-4 p-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6">
              {bees && bees.length > 0 ? (
                bees.map((bee) => {
                  const beeLevel = getLevelFromXP(bee.totalxp); // Calculate level
                  return (
                    <div
                      key={`${bee.classId}-${bee.name}`}
                      className="flex flex-col items-center p-0 transition-transform transform bg-yellow-100 rounded-lg shadow cursor-pointer w-22 hover:bg-yellow-200 hover:scale-105"
                      onClick={() => handleBeeClick(bee)}
                    >
                      {/* Replace Beesolo SVG with XPProgressArc */}
                      <XPProgressArc
                        totalXP={bee.totalxp}
                        onLevelUp={(newLevel, xpRequired) =>
                          handleLevelUp(newLevel, xpRequired)
                        }
                      />
                      <h3 className="mt-0 text-base font-medium">{bee.name}</h3>
                      {/* Level display removed */}
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-500">No bees available.</p>
              )}
            </div>

            {/* Popup Modal for Single Bee */}
            {isPopupOpen && selectedBee && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
                <div className="relative max-w-full p-6 mx-4 bg-white rounded-lg shadow-lg w-80">
                  <h3 className="mb-4 text-xl font-semibold">
                    Add 100 XP to {selectedBee.name}
                  </h3>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={handleClosePopup}
                      className="px-4 py-2 text-gray-700 bg-gray-300 rounded hover:bg-gray-400 focus:outline-none"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleAddXPSingleBee}
                      className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 focus:outline-none"
                    >
                      Add 100 XP
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Progress.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number.isRequired,
      currentApp: PropTypes.string, // Optional since it's removed
      classId: PropTypes.string.isRequired,
      logged: PropTypes.string.isRequired,
    })
  ),
};

export default Progress;