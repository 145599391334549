// src/components/Teachers/Bee copy.js

import React, { useState, useEffect, useRef } from 'react';
import beesolo from '../../assets/beesolo.svg';
import './Bee copy.css'; // Ensure this CSS file exists and is correctly named
import { ref as dbRef, onValue } from 'firebase/database';
import { rtdb } from '../../../firebase';

const Bee = ({ classId, boundaries, name, isOnline, isCurrentUser }) => {
  // Declare all Hooks at the top level, unconditionally
  const [currentApp, setCurrentApp] = useState('None');
  const [flipped, setFlipped] = useState(false);

  const beeSize = 18;
  const SPEED = 0.2;

  const containerWidth = boundaries?.width || 0;
  const containerHeight = boundaries?.height || 0;
  const centerX = containerWidth / 2;
  const centerY = containerHeight / 2;
  const safeRadiusX = containerWidth * 0.2;
  const safeRadiusY = containerHeight * 0.2;

  const xRef = useRef(centerX - beeSize / 2);
  const yRef = useRef(centerY - beeSize / 2);
  const dxRef = useRef((Math.random() - 0.5) * SPEED);
  const dyRef = useRef((Math.random() - 0.5) * SPEED);
  const targetDxRef = useRef((Math.random() - 0.5) * SPEED);
  const targetDyRef = useRef((Math.random() - 0.5) * SPEED);
  const flippedRef = useRef(dxRef.current < 0);
  const isReturningToCenterRef = useRef(false);

  const beeElementRef = useRef(null);
  const animationFrameId = useRef(null);

  // Firebase Listener for currentApp
  useEffect(() => {
    if (!classId || !name) {
      console.warn(`Bee component missing classId or name. classId: ${classId}, name: ${name}`);
      return;
    }

    console.log(`Setting up RTDB listener for Bee: ${name} in Class: ${classId}`);

    const currentAppPath = `activeclasses/${classId}/beeNames/${name}/currentApp`;
    const currentAppRef = dbRef(rtdb, currentAppPath);

    const handleValueChange = (snapshot) => {
      const app = snapshot.val();
      console.log(`Bee: ${name} - currentApp updated to: ${app}`);
      console.log(`Type of app: ${typeof app}`);
      if (typeof app === 'string') {
        setCurrentApp(app.charAt(0).toUpperCase() + app.slice(1));
      } else {
        console.warn(`Unexpected type for currentApp: ${typeof app}`);
        setCurrentApp('Home');
      }
    };

    const unsubscribe = onValue(currentAppRef, handleValueChange, (error) => {
      console.error(`Failed to fetch currentApp for bee ${name}:`, error);
    });

    return () => {
      console.log(`Removing RTDB listener for Bee: ${name}`);
      unsubscribe();
    };
  }, [classId, name]);

  // Log currentApp changes
  useEffect(() => {
    console.log(`currentApp state changed to: ${currentApp}`);
  }, [currentApp]);

  // Position and Animation Logic
  useEffect(() => {
    if (containerWidth === 0 || containerHeight === 0) return;

    if (
      xRef.current === centerX - beeSize / 2 &&
      yRef.current === centerY - beeSize / 2
    ) {
      xRef.current = Math.random() * (containerWidth - beeSize);
      yRef.current = Math.random() * (containerHeight - beeSize);
      if (beeElementRef.current) {
        beeElementRef.current.style.transform = `translate(${xRef.current}px, ${yRef.current}px)`;
      }
    }

    const updatePosition = () => {
      dxRef.current += (targetDxRef.current - dxRef.current) * 0.02;
      dyRef.current += (targetDyRef.current - dyRef.current) * 0.02;

      const velocityMagnitude = Math.sqrt(dxRef.current ** 2 + dyRef.current ** 2);
      if (velocityMagnitude > SPEED) {
        dxRef.current = (dxRef.current / velocityMagnitude) * SPEED;
        dyRef.current = (dyRef.current / velocityMagnitude) * SPEED;
      }

      let newX = xRef.current + dxRef.current;
      let newY = yRef.current + dyRef.current;

      let hasHitBoundary = false;

      if (newX < 0) {
        newX = 0;
        hasHitBoundary = true;
      } else if (newX > containerWidth - beeSize) {
        newX = containerWidth - beeSize;
        hasHitBoundary = true;
      }

      if (newY < 0) {
        newY = 0;
        hasHitBoundary = true;
      } else if (newY > containerHeight - beeSize) {
        newY = containerHeight - beeSize;
        hasHitBoundary = true;
      }

      if (hasHitBoundary && !isReturningToCenterRef.current) {
        isReturningToCenterRef.current = true;
        const directionX = centerX - (newX + beeSize / 2);
        const directionY = centerY - (newY + beeSize / 2);
        const distance = Math.sqrt(directionX ** 2 + directionY ** 2);

        if (distance !== 0) {
          const normX = directionX / distance;
          const normY = directionY / distance;

          targetDxRef.current = normX * SPEED;
          targetDyRef.current = normY * SPEED;
        }
      }

      xRef.current = newX;
      yRef.current = newY;

      if (beeElementRef.current) {
        beeElementRef.current.style.transform = `translate(${xRef.current}px, ${yRef.current}px)`;
      }

      const distanceFromCenter = {
        distanceX: Math.abs(newX + beeSize / 2 - centerX),
        distanceY: Math.abs(newY + beeSize / 2 - centerY),
      };

      if (
        distanceFromCenter.distanceX <= safeRadiusX &&
        distanceFromCenter.distanceY <= safeRadiusY &&
        isReturningToCenterRef.current
      ) {
        isReturningToCenterRef.current = false;
        targetDxRef.current = (Math.random() - 0.5) * SPEED;
        targetDyRef.current = (Math.random() - 0.5) * SPEED;
      }

      const currentlyFlipped = dxRef.current < 0;
      if (currentlyFlipped !== flippedRef.current) {
        flippedRef.current = currentlyFlipped;
        setFlipped(currentlyFlipped);
      }

      animationFrameId.current = requestAnimationFrame(updatePosition);
    };

    animationFrameId.current = requestAnimationFrame(updatePosition);

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [containerWidth, containerHeight, beeSize, centerX, centerY, safeRadiusX, safeRadiusY]);

  // Randomly change target velocities
  useEffect(() => {
    const changeTargetVelocity = () => {
      if (!isReturningToCenterRef.current) {
        targetDxRef.current = (Math.random() - 0.5) * SPEED;
        targetDyRef.current = (Math.random() - 0.5) * SPEED;
      }
    };

    const interval = setInterval(() => {
      changeTargetVelocity();
    }, Math.random() * 3000 + 2000); // Change every 2 to 5 seconds randomly

    return () => clearInterval(interval);
  }, [SPEED]);

  // Safeguard: If no name, render nothing
  if (!name || name.trim() === '') {
    console.warn(`Bee component received invalid name: "${name}". Not rendering.`);
    return null;
  }

  return (
    <div
      ref={beeElementRef}
      style={{
        position: 'absolute',
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: `translate(${xRef.current}px, ${yRef.current}px)`,
        willChange: 'transform',
      }}
    >
      {/* Bee Image */}
      <img
        src={beesolo}
        alt={`Flying Bee ${name}`} // Updated alt text to use name instead of id
        className={`beesolo ${flipped ? 'flipped' : ''}`}
        style={{
          width: beeSize,
          height: 'auto',
          pointerEvents: 'none',
          transition: 'transform 1s',
        }}
        aria-hidden="true"
      />

      {/* Bee Name (Conditionally Rendered) */}
      {isCurrentUser && (
        <div
          style={{
            textAlign: 'center',
            marginTop: 4,
            fontSize: '11px',
            color: '#333',
            textShadow: '1px 1px 2px rgba(255, 255, 255, 0.7)',
            backgroundColor: '#ffffff',
            border: '2px solid #DAA520',
            borderRadius: '8px',
            padding: '0px 3px',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            opacity: 1,
            visibility: 'visible',
            transition: 'opacity 0.5s, visibility 0.5s',
          }}
        >
          {name}
        </div>
      )}
    </div>
  );
};

export default Bee;
