// XPProgressArc Component

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; // Ensure PropTypes is imported
import Beesolo from '../../assets/beesolo.svg'; // Adjust path as needed

// Constants and utility functions
const MAX_LEVEL = 250;
const BASE_XP = 100;
const XP_INCREMENT_RATIO = 1.1;

const computeXpPerLevel = () => {
  const xpPerLevel = [BASE_XP];
  for (let i = 1; i < MAX_LEVEL; i++) {
    const nextXp = Math.round(xpPerLevel[i - 1] * XP_INCREMENT_RATIO);
    xpPerLevel.push(nextXp);
  }
  return xpPerLevel;
};

const xpPerLevel = computeXpPerLevel();

const XPProgressArc = ({ totalXP, onLevelUp }) => {
  const [level, setLevel] = useState(0);
  const [currentXP, setCurrentXP] = useState(0);
  const [displayXP, setDisplayXP] = useState(0);
  const [canLevelUp, setCanLevelUp] = useState(false);
  const animationRef = useRef(null);
  const levelUpTimeoutRef = useRef(null);
  const previousLevelRef = useRef(level); // Track previous level

  // Compute level based on total XP
  useEffect(() => {
    let accumulatedXP = 0;
    let lvl = 0;
    while (lvl < MAX_LEVEL && accumulatedXP + xpPerLevel[lvl] <= totalXP) {
      accumulatedXP += xpPerLevel[lvl];
      lvl++;
    }
    setLevel(lvl);
    setCurrentXP(totalXP - accumulatedXP);
  }, [totalXP]);

  // Current XP required for next level
  const currentXPRequired =
    level < MAX_LEVEL ? xpPerLevel[level] : xpPerLevel[MAX_LEVEL - 1];

  // Calculate progress percentage based on displayXP
  const progress = Math.min(displayXP / currentXPRequired, 1);

  // Arc parameters
  const radius = 80;
  const strokeWidth = 10;
  const center = 100;

  // Calculate the SVG path for the arc
  const getArcPath = (percentage) => {
    const angleStart = 180;
    const angleEnd = 180 + 180 * percentage;

    const startRad = (angleStart * Math.PI) / 180;
    const endRad = (angleEnd * Math.PI) / 180;

    const startX = center + radius * Math.cos(startRad);
    const startY = center + radius * Math.sin(startRad);
    const endX = center + radius * Math.cos(endRad);
    const endY = center + radius * Math.sin(endRad);

    const sweepAngle = 180 * percentage;
    const largeArcFlag = sweepAngle > 180 ? 1 : 0;
    const sweepFlag = 1;

    return `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;
  };

  // AnimateTo function with a callback
  const animateTo = (start, end, duration = 3000, callback) => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    const startTime = performance.now();

    const easeInOutCubic = (t) =>
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    const step = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progressFraction = Math.min(elapsed / duration, 1);
      const easeProgress = easeInOutCubic(progressFraction);
      const newDisplayXP = Math.round(start + (end - start) * easeProgress);
      setDisplayXP(newDisplayXP);

      if (progressFraction < 1) {
        animationRef.current = requestAnimationFrame(step);
      } else {
        setDisplayXP(end);
        if (callback) callback();
      }
    };

    animationRef.current = requestAnimationFrame(step);
  };

  // Animation Effect for Smooth XP Transition
  useEffect(() => {
    // Determine if a level-up has occurred
    if (level > previousLevelRef.current) {
      // Animate to 100%
      animateTo(displayXP, currentXPRequired, 1000, () => {
        // After reaching 100%, reset to 0 and animate to new currentXP
        setDisplayXP(0);
        animateTo(0, currentXP, 1000);
      });

      previousLevelRef.current = level;
    } else {
      // Normal XP increment
      animateTo(displayXP, currentXP, 3000);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [currentXP, currentXPRequired, level]);

  // Update canLevelUp state when XP reaches 100%
  useEffect(() => {
    setCanLevelUp(currentXP >= currentXPRequired && level < MAX_LEVEL);
  }, [currentXP, currentXPRequired, level]);

  // Auto Level-Up Effect
  useEffect(() => {
    if (canLevelUp) {
      // Set a timeout to auto level up after 2000ms
      levelUpTimeoutRef.current = setTimeout(() => {
        handleLevelUp(level + 1, currentXPRequired);
      }, 2000);
    }

    // Cleanup the timeout if canLevelUp changes before timeout completes
    return () => {
      if (levelUpTimeoutRef.current) {
        clearTimeout(levelUpTimeoutRef.current);
      }
    };
  }, [canLevelUp]);

  const handleLevelUp = () => {
    if (level >= MAX_LEVEL || !canLevelUp) return;

    // Implement any level-up related logic here.
    console.log('XPProgressArc: Level up triggered.');
    if (onLevelUp) {
      onLevelUp(level + 1, currentXPRequired);
    }
  };

  return (
    <div className="relative flex flex-col items-center gap-0 p-6 pointer-events-none">
      {/* Main Container has pointer-events: none to allow clicks to pass through */}
      <div className="relative">
        <div className="w-20 h-20 bg-gray-100 rounded-full" />

        {/* Adjusted SVG Positioning */}
        <svg
          className="absolute z-30 w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          viewBox="0 0 200 200"
        >
          <path
            d={getArcPath(1)}
            fill="none"
            stroke="#e5e7eb"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          <path
            d={getArcPath(progress)}
            fill="none"
            stroke="#4f46e5"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          {/* Static Bee in the Center */}
          <image
            href={Beesolo}
            x="70" // Adjusted to center the bee
            y="60"
            width="60"
            height="60"
          />
        </svg>

        {/* Updated Hive and Level Text */}
        <div className="absolute z-20 text-center transform -translate-x-1/2 -translate-y-0 top-12 left-1/2">
          <div className="text-xs font-bold text-gray-800">Hive</div>
          <div className="text-[10px] font-bold text-gray-800 whitespace-nowrap">
            Level: {level}
          </div>
        </div>
      </div>

      {/* Level Up Indicator */}
      <div className="mt-2">
        {canLevelUp && (
          <span className="text-sm font-bold text-green-500">
            Level Up!
          </span>
        )}
      </div>
    </div>
  );
};

XPProgressArc.propTypes = {
  totalXP: PropTypes.number.isRequired, // Updated prop name
  onLevelUp: PropTypes.func, // Optional callback
};

export default XPProgressArc;