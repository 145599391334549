// src/components/Teachers/Slideshow/SlideCreator.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import AnimateSlide from './AnimateSlide'; // Import the updated AnimateSlide component

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Define the list of shapes
  const shapes = [
    'square',
    'triangle',
    'circle',
    'rectangle',
    'pentagon',
    'hexagon',
    'star',
    'oval',
    'diamond',
    'heart',
  ];

  // Define movement options
  const movementOptions = [
    'Level 1 Position 1',
    'Level 1 Position 2',
    'Level 1 Position 3',
    'Level 2 Position 1',
    'Level 2 Position 2',
    'Level 2 Position 3',
    'Level 3 Position 1',
    'Level 3 Position 2',
    'Level 3 Position 3',
  ];

  // Define scene options
  const sceneOptions = [
    'Home',
    'Town',
    'Travel',
    'Write',
    'Read',
    'Compare',
    'Portrait',
    'Conversation',
  ];

  // Placeholder titles and subtitles
  const placeholderTitles = [
    "Welcome to our presentation.",
    "Today, we'll explore React components.",
    "Understanding state and props is crucial.",
    "Let's dive into lifecycle methods.",
    "Hooks have revolutionized React development.",
    "Managing side effects with useEffect.",
    "Context API for global state management.",
    "Optimizing performance in React apps.",
    "Routing with React Router.",
    "Handling forms and validations.",
  ];

  const placeholderSubtitles = [
    "Subtitle for slide 1.",
    "Subtitle for slide 2.",
    "Subtitle for slide 3.",
    "Subtitle for slide 4.",
    "Subtitle for slide 5.",
    "Subtitle for slide 6.",
    "Subtitle for slide 7.",
    "Subtitle for slide 8.",
    "Subtitle for slide 9.",
    "Subtitle for slide 10.",
  ];

  // Function to generate initial slides
  const generateInitialSlides = () => {
    const initialSlides = [];
    for (let i = 0; i < 10; i++) {
      initialSlides.push({
        id: Date.now() + i, // Ensure unique ID
        title: placeholderTitles[i],
        subtitle: placeholderSubtitles[i],
        theme: shapes[i % shapes.length], // Assign a unique shape
        movement: movementOptions[i % movementOptions.length], // Assign movement from movementOptions
        scene: 'Home', // Default scene
      });
    }
    return initialSlides;
  };

  // Initialize slides with 10 prefilled slides
  const [slides, setSlides] = useState(generateInitialSlides());

  // State to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // State to track navigation direction for animation
  const [navigationDirection, setNavigationDirection] = useState(0); // 1 for next, -1 for previous, 0 for direct navigation

  const currentSlide = slides[currentSlideIndex];

  // Function to add a new slide
  const addSlide = () => {
    const newSlideIndex = slides.length % shapes.length; // Cycle through shapes
    const newMovement = movementOptions[slides.length % movementOptions.length]; // Cycle through movementOptions
    const newSlide = {
      id: Date.now(),
      title: "New Slide",
      subtitle: "Subtitle goes here.",
      theme: shapes[newSlideIndex],
      movement: newMovement, // Set movement from movementOptions
      scene: 'Home', // Default scene
    };
    setSlides([...slides, newSlide]);
    // Navigate to the new slide
    setNavigationDirection(1);
    setCurrentSlideIndex(slides.length);
  };

  // Function to remove a slide by ID
  const removeSlide = (id) => {
    const slideIndex = slides.findIndex((s) => s.id === id);
    const updatedSlides = slides.filter((s) => s.id !== id);
    setSlides(updatedSlides);
    // Adjust currentSlideIndex if necessary
    if (updatedSlides.length === 0) {
      addSlide();
    } else {
      if (currentSlideIndex >= updatedSlides.length) {
        setNavigationDirection(-1);
        setCurrentSlideIndex(updatedSlides.length - 1);
      } else if (slideIndex < currentSlideIndex) {
        setNavigationDirection(-1);
        setCurrentSlideIndex(currentSlideIndex - 1);
      } else {
        // If removing the current slide, no direction change
        setNavigationDirection(0);
      }
    }
  };

  // Handlers for changing slide properties
  const handleTitleChange = (id, newTitle) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, title: newTitle } : s
      )
    );
  };

  const handleSubtitleChange = (id, newSubtitle) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, subtitle: newSubtitle } : s
      )
    );
  };

  const handleThemeChange = (id, newTheme) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, theme: newTheme } : s
      )
    );
  };

  const handleMovementChange = (id, newMovement) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, movement: newMovement } : s
      )
    );
  };

  const handleSceneChange = (id, newScene) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, scene: newScene } : s
      )
    );
  };

  // Handlers for navigating slides
  const handleNext = () => {
    if (currentSlideIndex < slides.length - 1) {
      setNavigationDirection(1);
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentSlideIndex > 0) {
      setNavigationDirection(-1);
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  // Handler for clicking on a slide preview
  const handleSlideClick = (index) => {
    if (index === currentSlideIndex) return; // No action if clicking the current slide
    if (index > currentSlideIndex) {
      setNavigationDirection(1);
    } else if (index < currentSlideIndex) {
      setNavigationDirection(-1);
    }
    setCurrentSlideIndex(index);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
      {/* Fullscreen Modal Container */}
      <div className="flex flex-col w-full h-full bg-white rounded-none shadow-none md:flex-row">
        
        {/* **Left Panel (Mini Slide Previews)** */}
        <div className="flex flex-col w-full p-4 overflow-y-auto border-r md:w-1/4">
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-lg font-medium">Slides</h4>
            <button
              type="button"
              onClick={addSlide}
              className="flex items-center justify-center w-8 h-8 text-white bg-green-500 rounded-full hover:bg-green-600 focus:outline-none"
              aria-label="Add Slide"
            >
              +
            </button>
          </div>
          <ul className="flex-1 space-y-4">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => handleSlideClick(index)}
                className={`cursor-pointer rounded border ${
                  currentSlideIndex === index
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:bg-gray-100'
                }`}
              >
                <div className="flex items-center p-2">
                  {/* **Number Indicator** */}
                  <span className="flex-shrink-0 w-6 text-sm font-semibold text-center text-gray-700">
                    {index + 1}
                  </span>

                  {/* **Mini Slide Preview with 16:9 Aspect Ratio** */}
                  <div className="w-24 mx-4">
                    <div className="flex items-center justify-center w-full overflow-hidden rounded aspect-video bg-gray-50">
                      <p className="px-2 text-xs text-center text-gray-700">
                        {slide.title.length > 20
                          ? `${slide.title.substring(0, 20)}...`
                          : slide.title}
                      </p>
                    </div>
                  </div>

                  {/* **Remove Button** */}
                  {slides.length > 1 && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSlide(slide.id);
                      }}
                      className="flex-shrink-0 text-red-500 hover:text-red-700"
                      aria-label={`Remove Slide ${index + 1}`}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* **Right Panel (Slide Editor)** */}
        <div className="w-full p-6 overflow-y-auto md:w-3/4">
          {/* **Header with Title and Theme Selectors** */}
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold">
              {currentSlide
                ? `Slide ${currentSlideIndex + 1}`
                : 'Create a New Slide'}
            </h3>
            {currentSlide && (
              <div className="flex space-x-4">
                {/* **Theme Selector** */}
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">
                    Theme
                  </label>
                  <select
                    value={currentSlide.theme}
                    onChange={(e) =>
                      handleThemeChange(currentSlide.id, e.target.value)
                    }
                    className="w-full px-3 py-2 bg-white border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  >
                    {shapes.map((shape) => (
                      <option key={shape} value={shape}>
                        {shape.charAt(0).toUpperCase() + shape.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>

                {/* **Movement Selector** */}
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">
                    Movement
                  </label>
                  <select
                    value={currentSlide.movement}
                    onChange={(e) =>
                      handleMovementChange(currentSlide.id, e.target.value)
                    }
                    className="w-full px-3 py-2 bg-white border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  >
                    {movementOptions.map((movement) => (
                      <option key={movement} value={movement}>
                        {movement}
                      </option>
                    ))}
                  </select>
                </div>

                {/* **Scene Selector** */}
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">
                    Scene
                  </label>
                  <select
                    value={currentSlide.scene}
                    onChange={(e) =>
                      handleSceneChange(currentSlide.id, e.target.value)
                    }
                    className="w-full px-3 py-2 bg-white border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  >
                    {sceneOptions.map((scene) => (
                      <option key={scene} value={scene}>
                        {scene}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <Button
              onClick={onClose}
              variant="ghost"
              className="ml-4 text-2xl leading-none"
              aria-label="Close Slide Creator"
            >
              &times;
            </Button>
          </div>
          
          {currentSlide && (
            <div className="space-y-6">
              {/* **AnimateSlide Component with Increased Size and Clipping** */}
              <div className="flex flex-col items-center justify-center w-full p-4 overflow-hidden bg-gray-100 border rounded-md aspect-w-16 aspect-h-9 md:aspect-h-12">
                <AnimateSlide
                  theme={currentSlide.theme}
                  movement={currentSlide.movement}
                  scene={currentSlide.scene}
                  direction={navigationDirection}
                />
              </div>

              {/* **Navigation Buttons Moved Below AnimateSlide** */}
              <div className="flex justify-center mt-4 space-x-4">
                <button
                  type="button"
                  onClick={handlePrevious}
                  disabled={currentSlideIndex === 0}
                  className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                    currentSlideIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  Previous
                </button>
                <button
                  type="button"
                  onClick={handleNext}
                  disabled={currentSlideIndex === slides.length - 1}
                  className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                    currentSlideIndex === slides.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  Next
                </button>
              </div>

              {/* **Title Text Box** */}
              <div className="p-4 border rounded-md">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={currentSlide.title}
                  onChange={(e) =>
                    handleTitleChange(currentSlide.id, e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter title"
                />
              </div>

              {/* **Subtitle Text Box** */}
              <div className="p-4 border rounded-md">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Subtitle
                </label>
                <input
                  type="text"
                  value={currentSlide.subtitle}
                  onChange={(e) =>
                    handleSubtitleChange(currentSlide.id, e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter subtitle"
                />
              </div>
            </div>
          )}

          {/* **Action Buttons (Cancel and Save) Moved Down** */}
          <div className="flex justify-end mt-6 space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-300 rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                onPopSlide && onPopSlide(slides);
                onClose();
              }}
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
            >
              Save Slides
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

SlideCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPopSlide: PropTypes.func,
};

export default SlideCreator;
