// src/components/Teachers/Tests/FirstPersonControls.jsx

import React, { useRef, useEffect, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const FirstPersonControls = () => {
  const { camera, gl: { domElement } } = useThree();
  
  // State to track if pointer is locked
  const [isLocked, setIsLocked] = useState(false);

  // State to keep track of pressed keys
  const move = useRef({
    forward: false,
    backward: false,
    left: false,
    right: false,
    up: false,
    down: false,
  });

  // Movement speed (units per second)
  const velocity = 5;

  // Rotation state
  const rotation = useRef({ yaw: 0, pitch: 0 });
  const sensitivity = 0.002; // Mouse sensitivity

  useEffect(() => {
    // Reset camera rotation on mount
    camera.rotation.set(0, 0, 0);
    camera.up.set(0, 1, 0);

    // **Set the rotation order to 'YXZ' for proper first-person controls**
    camera.rotation.order = 'YXZ';
  }, [camera]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      console.log('Context menu opened and prevented.');
    };
    domElement.addEventListener('contextmenu', handleContextMenu);

    return () => {
      domElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [domElement]);

  useEffect(() => {
    const onKeyDown = (event) => {
      switch (event.code) {
        case 'KeyS':
          move.current.forward = true;
          break;
        case 'KeyW':
          move.current.backward = true;
          break;
        case 'KeyD':
          move.current.left = true;
          break;
        case 'KeyA':
          move.current.right = true;
          break;
        case 'Space':
          move.current.up = true;
          break;
        case 'ShiftLeft':
        case 'ShiftRight':
          move.current.down = true;
          break;
        default:
          break;
      }
      if (['KeyQ', 'KeyE'].includes(event.code)) {
        event.preventDefault(); // Disable rolling
      }
    };

    const onKeyUp = (event) => {
      switch (event.code) {
        case 'KeyS':
          move.current.forward = false;
          break;
        case 'KeyW':
          move.current.backward = false;
          break;
        case 'KeyD':
          move.current.left = false;
          break;
        case 'KeyA':
          move.current.right = false;
          break;
        case 'Space':
          move.current.up = false;
          console.log('Key Released: Space (Stop Moving Up)');
          break;
        case 'ShiftLeft':
        case 'ShiftRight':
          move.current.down = false;
          console.log('Key Released: Shift (Stop Moving Down)');
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  // Handle pointer lock
  useEffect(() => {
    const onPointerLockChange = () => {
      const lockedElement = document.pointerLockElement;
      setIsLocked(lockedElement === domElement);
    };

    const onPointerLockError = () => {
      console.error('Pointer Lock Error');
    };

    document.addEventListener('pointerlockchange', onPointerLockChange);
    document.addEventListener('pointerlockerror', onPointerLockError);

    return () => {
      document.removeEventListener('pointerlockchange', onPointerLockChange);
      document.removeEventListener('pointerlockerror', onPointerLockError);
      // Ensure pointer lock is released when component unmounts
      if (document.pointerLockElement === domElement) {
        document.exitPointerLock();
      }
    };
  }, [domElement]);

  // Request pointer lock on click
  useEffect(() => {
    const onClick = () => {
      if (!isLocked) {
        domElement.requestPointerLock().catch((err) => {
          console.error('Failed to request pointer lock:', err);
        });
      }
    };

    domElement.addEventListener('click', onClick);

    return () => {
      domElement.removeEventListener('click', onClick);
    };
  }, [domElement, isLocked]);

  // Handle mouse movement for camera rotation
  useEffect(() => {
    if (!isLocked) return;

    const onMouseMove = (event) => {
      const { movementX, movementY } = event;

      // Update rotation angles
      rotation.current.yaw -= movementX * sensitivity;
      rotation.current.pitch -= movementY * sensitivity;

      // Clamp the pitch to prevent flipping
      const PI_2 = Math.PI / 2;
      rotation.current.pitch = Math.max(-PI_2, Math.min(PI_2, rotation.current.pitch));

      // **Apply rotations to the camera with the updated rotation order**
      camera.rotation.set(rotation.current.pitch, rotation.current.yaw, 0);
    };

    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [isLocked, camera]);

  // Update camera position on each frame based on pressed keys
  useFrame((state, delta) => {
    if (!isLocked) return;

    const direction = new THREE.Vector3();
    const velocityVector = new THREE.Vector3();

    // Determine the direction based on keys pressed
    if (move.current.forward) velocityVector.z -= 1;
    if (move.current.backward) velocityVector.z += 1;
    if (move.current.left) velocityVector.x -= 1;
    if (move.current.right) velocityVector.x += 1;
    if (move.current.up) velocityVector.y += 1;
    if (move.current.down) velocityVector.y -= 1;

    // If no movement keys are pressed, do nothing
    if (
      !move.current.forward &&
      !move.current.backward &&
      !move.current.left &&
      !move.current.right &&
      !move.current.up &&
      !move.current.down
    ) {
      return;
    }

    // Normalize to ensure consistent movement speed in all directions
    direction.copy(velocityVector).normalize();

    // Calculate the movement delta based on velocity and delta time
    const moveDistance = velocity * delta;

    // Get the camera's forward and right vectors
    const forward = new THREE.Vector3();
    camera.getWorldDirection(forward);
    forward.y = 0; // Keep movement horizontal
    forward.normalize();

    const right = new THREE.Vector3();
    right.crossVectors(camera.up, forward).normalize();

    // Calculate the final movement vector
    const deltaPosition = new THREE.Vector3()
      .addScaledVector(forward, direction.z * moveDistance)
      .addScaledVector(right, direction.x * moveDistance)
      .add(new THREE.Vector3(0, direction.y * moveDistance, 0));

    // Update camera position
    camera.position.add(deltaPosition);
  });

  return null; // No JSX needed as we're handling pointer lock manually
};

export default FirstPersonControls;