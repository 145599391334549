// src/components/Teachers/LaunchQuiz/LaunchQuiz.js

import React, { useState, useEffect } from 'react';
import Button from '../ui/Button';

// Mock function to simulate fetching quiz data by ID
const fetchQuizById = (quizId) => {
  // In a real application, replace this with an API call
  const quizzesDatabase = [
    {
      id: 1,
      title: 'Math Basics',
      description: 'A quiz on basic mathematics concepts.',
      questions: [
        {
          question: 'What is 2 + 2?',
          options: ['3', '4', '5', '6'],
          correctAnswer: '4',
        },
        {
          question: 'What is 5 x 3?',
          options: ['15', '10', '8', '20'],
          correctAnswer: '15',
        },
        // Add more questions as needed
      ],
    },
    {
      id: 2,
      title: 'Science Fundamentals',
      description: 'Test your knowledge on basic science topics.',
      questions: [
        {
          question: 'What planet is known as the Red Planet?',
          options: ['Earth', 'Mars', 'Jupiter', 'Saturn'],
          correctAnswer: 'Mars',
        },
        {
          question: 'What is H2O commonly known as?',
          options: ['Salt', 'Hydrogen Peroxide', 'Water', 'Oxygen'],
          correctAnswer: 'Water',
        },
        // Add more questions as needed
      ],
    },
    // Add more quizzes as needed
  ];

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const quiz = quizzesDatabase.find((q) => q.id === quizId);
      if (quiz) {
        resolve(quiz);
      } else {
        reject('Quiz not found');
      }
    }, 500); // Simulate network delay
  });
};

const LaunchQuiz = ({ quizId, quizData, onClose }) => {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [isQuizEnded, setIsQuizEnded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paceMode, setPaceMode] = useState('student'); // 'student' or 'teacher'
  const [submittedQuestions, setSubmittedQuestions] = useState([]); // Tracks submitted questions

  // Fetch quiz data on mount
  useEffect(() => {
    if (quizData) {
      setQuiz(quizData);
      setLoading(false);
    } else if (quizId) {
      fetchQuizById(quizId)
        .then((fetchedQuiz) => {
          setQuiz(fetchedQuiz);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else {
      setError('No quiz data provided');
      setLoading(false);
    }
  }, [quizId, quizData]);

  const startQuiz = () => {
    setIsQuizStarted(true);
  };

  const handleOptionSelect = (option) => {
    // Prevent changing answers after submission
    if (submittedQuestions.includes(currentQuestionIndex)) return;

    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[currentQuestionIndex] = option;
    setSelectedOptions(updatedSelectedOptions);
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const togglePaceMode = (mode) => {
    setPaceMode(mode);
  };

  const submitQuiz = () => {
    // Check if the current question has already been submitted
    if (!submittedQuestions.includes(currentQuestionIndex)) {
      setSubmittedQuestions([...submittedQuestions, currentQuestionIndex]);
    }

    // **Removed**: Automatically ending the quiz when all questions are submitted
    // if (submittedQuestions.length + 1 === quiz.questions.length) {
    //   calculateScore();
    //   setIsQuizEnded(true);
    // }
  };

  const calculateScore = () => {
    let calculatedScore = 0;
    quiz.questions.forEach((question, index) => {
      if (selectedOptions[index] === question.correctAnswer) {
        calculatedScore += 1;
      }
    });
    setScore(calculatedScore);
  };

  const finishQuiz = () => {
    calculateScore();
    setIsQuizEnded(true);
  };

  const restartQuiz = () => {
    setCurrentQuestionIndex(0);
    setIsQuizEnded(false);
    setIsQuizStarted(false);
    setSelectedOptions([]);
    setScore(0);
    setPaceMode('student');
    setSubmittedQuestions([]); // Reset submission state
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full p-6 bg-gray-50">
        <p className="text-lg text-gray-700">Loading Quiz...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full p-6 bg-gray-50">
        <p className="mb-4 text-lg text-red-500">Error: {error}</p>
        <Button
          onClick={onClose}
          variant="primary"
          className="px-6 py-3 text-white bg-red-500 rounded-lg hover:bg-red-600"
        >
          Close
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-start w-full h-full p-6 space-y-6 bg-gray-50">
      <header className="flex items-center justify-between w-full mb-6">
        <h2 className="text-3xl font-bold text-blue-600">{quiz.title}</h2>
        <Button
          onClick={onClose}
          variant="ghost"
          className="text-2xl text-red-500 hover:text-red-700"
          aria-label="Close Launch Quiz"
        >
          &times;
        </Button>
      </header>

      {!isQuizStarted && !isQuizEnded && (
        <div className="flex flex-col items-center">
          <p className="mb-8 text-lg text-gray-700">{quiz.description}</p>
          <Button
            onClick={startQuiz}
            variant="primary"
            className="px-6 py-3 text-lg text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-600"
          >
            Start Quiz
          </Button>
        </div>
      )}

      {isQuizStarted && !isQuizEnded && (
        <div className="w-full max-w-2xl p-8 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-2xl font-semibold text-gray-800">
              {currentQuestionIndex + 1}. {quiz.questions[currentQuestionIndex].question}
            </h3>
            <div className="flex space-x-2">
              <Button
                onClick={() => togglePaceMode('teacher')}
                variant={paceMode === 'teacher' ? 'primary' : 'secondary'}
                className={`px-4 py-2 rounded-lg ${
                  paceMode === 'teacher'
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                Teacher Paced
              </Button>
              <Button
                onClick={() => togglePaceMode('student')}
                variant={paceMode === 'student' ? 'primary' : 'secondary'}
                className={`px-4 py-2 rounded-lg ${
                  paceMode === 'student'
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                Student Paced
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            {quiz.questions[currentQuestionIndex].options.map((option, index) => {
              // Determine if this option is selected
              const isSelected = selectedOptions[currentQuestionIndex] === option;

              // Determine if this question has been submitted
              const isQuestionSubmitted = submittedQuestions.includes(currentQuestionIndex);

              // Determine the background color based on submission status
              let bgColor = 'bg-gray-200';
              if (isQuestionSubmitted && isSelected) {
                bgColor = 'bg-orange-500 text-white'; // Orange for submitted answer
              } else if (isSelected) {
                bgColor = 'bg-blue-200';
              }

              return (
                <Button
                  key={index}
                  onClick={() => handleOptionSelect(option)}
                  variant="option"
                  className={`w-full px-4 py-2 text-lg text-left rounded-lg ${
                    isQuestionSubmitted
                      ? bgColor
                      : `hover:bg-gray-300 ${bgColor}`
                  }`}
                  disabled={isQuestionSubmitted} // Disable options after submission
                >
                  {option}
                </Button>
              );
            })}
          </div>

          <div className="flex items-center justify-between mt-4">
            <div className="text-sm text-gray-500">
              Question {currentQuestionIndex + 1} of {quiz.questions.length}
            </div>
            <div className="flex space-x-2">
              <Button
                onClick={goToPreviousQuestion}
                variant="secondary"
                className={`px-4 py-2 rounded-lg ${
                  currentQuestionIndex === 0
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'bg-gray-500 text-white hover:bg-gray-600'
                }`}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
              <Button
                onClick={
                  currentQuestionIndex === quiz.questions.length - 1
                    ? finishQuiz
                    : goToNextQuestion
                }
                variant="secondary"
                className={`px-4 py-2 rounded-lg ${
                  // Style the Finish button differently if needed
                  currentQuestionIndex === quiz.questions.length - 1
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-gray-500 text-white hover:bg-gray-600'
                }`}
              >
                {currentQuestionIndex === quiz.questions.length - 1
                  ? 'Finish'
                  : 'Next'}
              </Button>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-center mt-6">
            <Button
              onClick={submitQuiz}
              variant="primary"
              className={`px-6 py-3 text-lg rounded-lg shadow-lg ${
                submittedQuestions.includes(currentQuestionIndex)
                  ? 'bg-gray-300 text-gray-700 cursor-not-allowed'
                  : 'text-white bg-green-500 hover:bg-green-600'
              }`}
              disabled={
                selectedOptions[currentQuestionIndex] === undefined ||
                submittedQuestions.includes(currentQuestionIndex)
              }
            >
              {submittedQuestions.includes(currentQuestionIndex)
                ? 'Submitted'
                : 'Submit Answer'}
            </Button>
          </div>
        </div>
      )}

      {isQuizEnded && (
        <div className="flex flex-col items-center">
          <h3 className="mb-4 text-2xl font-semibold text-green-600">Quiz Completed!</h3>
          <p className="mb-6 text-lg text-gray-700">
            Your Score: {score} / {quiz.questions.length}
          </p>
          <div className="flex space-x-4">
            <Button
              onClick={restartQuiz}
              variant="primary"
              className="px-6 py-3 text-lg text-white bg-green-500 rounded-lg shadow-lg hover:bg-green-600"
            >
              Restart Quiz
            </Button>
            <Button
              onClick={onClose}
              variant="secondary"
              className="px-6 py-3 text-lg text-white bg-gray-500 rounded-lg shadow-lg hover:bg-gray-600"
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LaunchQuiz;
