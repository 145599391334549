import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as Castle } from './SVGs/Castle.svg'; // Import the Castle SVG as a React component

// Define animation variants that handle position, opacity, and scale
const svgVariants = {
  initial: (custom) => ({
    x: custom.direction === 1 ? 500 : -500, // Enter from right or left
    opacity: 1,
    scale: 1, // Start with a default scale
  }),
  animate: (custom) => ({
    x: 0,
    opacity: 1,
    scale: custom.scale, // Apply current scale
    transition: {
      x: { duration: 9, ease: 'easeInOut' },
      opacity: { duration: 3, ease: 'easeInOut' },
      scale: { duration: 9, ease: 'easeInOut' }, // Smooth scale transition
    },
  }),
  exit: (custom) => ({
    x: custom.direction === 1 ? -500 : 500, // Exit to left or right
    opacity: 1,
    scale: custom.scale, // Maintain current scale during exit
    transition: {
      x: { duration: 3, ease: 'easeInOut' },
      opacity: { duration: 0.5, ease: 'easeInOut' },
      // Do not animate scale on exit to keep it consistent
    },
  }),
};

const textVariants = {
  initial: (direction) => ({
    opacity: 0,
    y: direction === 1 ? 20 : -20, // Slide up or down based on direction
  }),
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
  exit: (direction) => ({
    opacity: 0,
    y: direction === 1 ? -20 : 20,
    transition: { duration: 0.5 },
  }),
};

// Helper function to determine scale based on movement level
const getZoomLevel = (movement) => {
  const match = movement.match(/Level (\d+)/);
  if (match) {
    const level = parseInt(match[1], 10);
    if (level === 1) return 1;
    if (level === 2) return 4; // 25% zoom in
    if (level === 3) return 16;  // 50% zoom in
  }
  return 1; // Default scale if level is not found
};

const AnimateSlide = ({ theme, movement, scene, direction }) => {
  const [currentMovement, setCurrentMovement] = useState(movement);
  const [currentScene, setCurrentScene] = useState(scene);
  const [currentScale, setCurrentScale] = useState(getZoomLevel(movement));

  useEffect(() => {
    // Update movement, scene, and scale when props change
    setCurrentMovement(movement);
    setCurrentScene(scene);
    setCurrentScale(getZoomLevel(movement));
  }, [theme, movement, scene]);

  // Function to render SVG based on theme
  const renderShapeSVG = (shape) => {
    switch (shape) {
      case 'triangle':
        return <polygon points="8,2 14,14 2,14" fill="#ef4444" />;
      case 'circle':
        return <circle cx="8" cy="8" r="6" fill="#10b981" />;
      case 'rectangle':
        return <rect x="2" y="5" width="12" height="6" fill="#f59e0b" />;
      case 'pentagon':
        return <polygon points="8,1 14,6 12,14 4,14 2,6" fill="#ec4899" />;
      case 'hexagon':
        return <polygon points="4,2 12,2 16,8 12,14 4,14 0,8" fill="#3b82f6" />;
      case 'star':
        return (
          <polygon
            points="8,1 10,6 16,6 11,10 13,15 8,12 3,15 5,10 0,6 6,6"
            fill="#a855f7"
          />
        );
      case 'oval':
        return <ellipse cx="8" cy="8" rx="6" ry="4" fill="#14b8a6" />;
      case 'diamond':
        return <polygon points="8,1 15,8 8,15 1,8" fill="#f43f5e" />;
      case 'heart':
        return (
          <path
            d="M8 12s4-3.3 4-6a4 4 0 10-8 0c0 2.7 4 6 4 6z"
            fill="#ef4444"
          />
        );
      case 'square':
        // Replace the square with the Castle SVG
        return <Castle className="w-full h-full" />;
      default:
        return <rect width="16" height="16" fill="#e5e7eb" />;
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center w-full h-full p-4 overflow-hidden bg-gray-100 border rounded-md">
      {/* SVG Container with Animation */}
      <AnimatePresence mode="wait" custom={{ direction, scale: currentScale }}>
        {theme === 'square' ? (
          // Render the Castle SVG as a motion.div with scale handled in animate
          <motion.div
            key="castle" // Unique key for Castle
            className="w-96 h-96" // Increased size from w-48 h-48 to w-96 h-96
            variants={svgVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={{ direction, scale: currentScale }} // Pass direction and scale
          >
            <Castle className="w-full h-full" /> {/* Render the Castle SVG as a component */}
          </motion.div>
        ) : (
          // Render other shapes within motion.svg with scale handled in animate
          <motion.svg
            key={theme} // Key to trigger animation on theme change
            className="w-96 h-96" // Increased size from w-48 h-48 to w-96 h-96
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
            variants={svgVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={{ direction, scale: currentScale }} // Pass direction and scale
          >
            {renderShapeSVG(theme)}
          </motion.svg>
        )}
      </AnimatePresence>

      {/* Movement Text Display with Animation */}
      <AnimatePresence mode="wait" custom={direction}>
        <motion.div
          key={`movement-${theme}`} // Key based on theme to sync with SVG animation
          className="absolute text-center transform -translate-y-1/2 top-1/2"
          variants={textVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          custom={direction}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <p className="text-lg font-semibold text-gray-800">{currentMovement}</p>
        </motion.div>
      </AnimatePresence>

      {/* Scene Text Display with Animation */}
      <AnimatePresence mode="wait" custom={direction}>
        <motion.div
          key={`scene-${theme}`} // Key based on theme to sync with SVG animation
          className="absolute mt-8 text-center transform -translate-y-1/2 top-1/2"
          variants={textVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          custom={direction}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <p className="font-medium text-gray-600 text-md">{currentScene}</p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

AnimateSlide.propTypes = {
  theme: PropTypes.string.isRequired,
  movement: PropTypes.string.isRequired,
  scene: PropTypes.string.isRequired,
  direction: PropTypes.number.isRequired, // Ensure direction is required
};

export default AnimateSlide;
