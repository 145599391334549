// src/components/Teachers/games/games.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../ui/Card';
import Button from '../ui/Button'; // Assuming you have a Button component
import { CSSTransition } from 'react-transition-group';
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

// **Import FaTimes from the Icons Directory**
import { FaTimes } from '../../assets/icons'; // Adjust the relative path if necessary

// **Import JPEG Images for Each Game**
import BeeRunnerImage from './GameImages/BeeRunner.jpg';
import HiveBuilderImage from './GameImages/HiveBuilder.jpg';
import HiveQuestImage from './GameImages/HiveQuest.jpg';
import HoneyHuntImage from './GameImages/Honeyhunt.jpg';
import NectarNinjaImage from './GameImages/NectarNinja.jpg';
import StringerStrikeImage from './GameImages/StingerStrike.jpg'; // Updated image name

// **Mapping game IDs to their respective JPEG images**
const gameImages = {
  '1': HoneyHuntImage,
  '3': StringerStrikeImage, // Updated to match the new image name
  '4': NectarNinjaImage,
  '5': HiveBuilderImage,
  '7': BeeRunnerImage,
  '8': HiveQuestImage,
};

// Function to add ordinal suffix to the day
const getOrdinal = (n) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

// Function to format the current date and time
const getFormattedDate = () => {
  const now = new Date();
  const options = { weekday: 'long', month: 'long' };
  const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
  const month = now.toLocaleDateString('en-US', { month: 'long' });
  const day = getOrdinal(now.getDate());
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
  return `${weekday} the ${day} of ${month} ${formattedTime}`;
};

const Games = ({ onClose }) => {
  const [currentDate, setCurrentDate] = useState(getFormattedDate());

  // Update the date and time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(getFormattedDate());
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  // Bee-themed games array with image association
  const beeGames = [
    { id: '1', name: 'Honey Hunt' },
    { id: '3', name: 'Stringer Strike' }, // Updated game name
    { id: '4', name: 'Nectar Ninja' },
    { id: '5', name: 'Hive Builder' },
    { id: '7', name: 'Bee Runner' },
    { id: '8', name: 'Hive Quest' },
  ];

  return (
    <CSSTransition in={true} timeout={300} classNames="modal" unmountOnExit>
      <div className="fixed inset-0 z-50 flex flex-col overflow-hidden bg-white">
        {/* Header Section with Title and Date/Time */}
        <div className="flex flex-col items-center justify-center w-full px-4 py-4 bg-gray-100 shadow-md">
          {/* Title */}
          <h2 className="text-3xl font-bold text-center">Games</h2>
          {/* Current Date and Time */}
          <div className="mt-2 text-sm font-medium text-gray-600">
            {currentDate}
          </div>
        </div>

        {/* Banner Area */}
        <div
          className="w-full"
          style={{
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Adjusted shadow for consistency
          }}
        >
          {/* Banner image with fixed height */}
          <img
            src={PuzzleBanner}
            alt="Banner"
            className="object-cover w-full h-24"
          />
        </div>

        {/* Close Button */}
        <div className="absolute top-4 right-4">
          <button
            onClick={onClose}
            className="text-3xl font-bold text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Close Modal"
          >
            <FaTimes />
          </button>
        </div>

        {/* Main Content Area */}
        <div className="flex flex-col flex-grow p-6 overflow-auto">
          {/* Games List as Tiles */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Available Games</h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {beeGames.map((game) => {
                const gameImage = gameImages[game.id];
                return (
                  <Card key={game.id} className="flex flex-col items-center px-0 py-4">
                    {/* Specific Image */}
                    <div className="w-full mx-auto mb-4 overflow-hidden rounded-lg max-w-40 aspect-square">
                      <img
                        src={gameImage}
                        alt={game.name}
                        className="object-cover object-center w-full h-full"
                      />
                    </div>
                    <span className="mb-2 text-lg font-medium">{game.name}</span>
                    {/* Example Button */}
                    <Button
                      onClick={() => alert(`Launching ${game.name}!`)}
                      className="px-4 py-2 text-white bg-yellow-500 rounded hover:bg-yellow-600"
                      aria-label={`Launch ${game.name}`}
                    >
                      Launch
                    </Button>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

// PropTypes for validation
Games.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Games;
