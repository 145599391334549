// src/components/Teachers/Whiteboard/TeacherExcalidrawWhiteboard.js

import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Excalidraw, exportToBlob } from '@excalidraw/excalidraw';
import { useWhiteboardData } from './whiteboardData'; // Adjust the path as necessary
import { useDataController } from '../../../contexts/datacontroller'; // Import DataController
import { ref as dbRef, set } from 'firebase/database';
import { rtdb } from '../../../firebase';
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported

// Import the banner image
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

const ExcalidrawModal = ({
  show,
  handleClose,
  title = 'Whiteboard',
  initialData = null,
  activeClassIDs = [],
  isLoading = false,
  onSave = () => {}
}) => {
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);

  // Ref for debouncing
  const debounceTimer = useRef(null);
  const DEBOUNCE_DELAY = 500; // milliseconds

  // Toolbar state
  const [isLocked, setIsLocked] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);

  // Utilize the custom hook
  const { updateWhiteboardData, sendWhiteboardData } = useWhiteboardData();
  const { setModuleStatus } = useDataController(); // If needed

  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const options = { weekday: 'long', month: 'long' };
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
    return `${weekday} the ${day} of ${month} ${formattedTime}`;
  };

  const handleCloseModal = useCallback(async () => {
    handleClose(); // Close the modal immediately

    if (excalidrawAPI) {
      try {
        const elements = excalidrawAPI.getSceneElements();
        const blob = await exportToBlob({
          elements,
          mimeType: 'image/png',
          appState: excalidrawAPI.getAppState()
        });
        const data = { elements, blob };

        // Call the onSave prop if provided
        onSave(data);

        // Send blob data to whiteboardData.js for further handling
        sendWhiteboardData(data, activeClassIDs)
          .then(() => {
            console.log('Data successfully sent to RTDB.');
          })
          .catch(error => {
            console.error('Error sending data to RTDB:', error);
          });
      } catch (error) {
        console.error('Error during modal close operations:', error);
      }
    }
  }, [handleClose, excalidrawAPI, onSave, sendWhiteboardData, activeClassIDs]);

  // Hide buttons and fix menu positioning
  useEffect(() => {
    if (show) {
      const hideButtonsAndFixMenu = () => {
        const selectors = [
          '.layer-ui__wrapper__top-right.zen-mode-transition',
          'button[title="Library"]',
          '[aria-label="Library"]',
          '.library-button',
          '.ToolIcon__library',
          '.help-icon',
          '[aria-label="Help"]',
          'button[title="Help"]',
          '.help-button',
          '.layer-ui__wrapper__footer-right button',
          'button[aria-label="Help"]',
          '.layer-ui__wrapper__footer-right [title="?"]',
          // Add selectors for social media links
          'a[aria-label="GitHub"]',
          'a[aria-label="Discord"]',
          'a[aria-label="Twitter"]',
          '.dropdown-menu-group',  // This will hide the entire "Excalidraw links" section
          'div[style*="height: 1px"]' // Hide the dividers
        ];

        selectors.forEach(selector => {
          const elements = document.querySelectorAll(selector);
          elements.forEach(el => {
            if (el) el.style.display = 'none';
          });
        });

        // Updated menu positioning
        const popover = document.querySelector('.Island.App-menu__popover');
        if (popover) {
          popover.style.left = 'auto';
          popover.style.right = 'auto';
          popover.style.transformOrigin = 'top left';
          popover.style.transform = 'none';
        }
      };

      // Create a MutationObserver to watch for changes
      const observer = new MutationObserver((mutations) => {
        hideButtonsAndFixMenu();
      });

      // Start observing the document with the configured parameters
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['style', 'class']
      });

      // Updated custom styles
      const style = document.createElement('style');
      style.textContent =
        `.excalidraw .Island.App-menu__popover {
          position: absolute !important;
          left: auto !important;
          right: auto !important;
          transform: none !important;
          margin-left: 40px !important;
        }
        .excalidraw-wrapper {
          overflow: visible !important;
        }
        .layer-ui__wrapper {
          overflow: visible !important;
        }
        .excalidraw .popover {
          position: absolute !important;
          left: auto !important;
          right: auto !important;
          transform: none !important;
          margin-left: 40px !important;
        }
        .Island.dropdown-menu-container {
          position: absolute !important;
          left: auto !important;
          right: auto !important;
          transform: none !important;
          margin-left: 5px !important;
        }
        /* Hide social media links and their container */
        .dropdown-menu-group,
        a[aria-label="GitHub"],
        a[aria-label="Discord"],
        a[aria-label="Twitter"] {
          display: none !important;
        }`;
      document.head.appendChild(style);

      // Initial attempt to hide and fix
      hideButtonsAndFixMenu();

      // Cleanup
      return () => {
        observer.disconnect();
        document.head.removeChild(style);
      };
    }
  }, [show]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && show) {
        handleCloseModal();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [show, handleCloseModal]);

  // Handle Excalidraw onChange for real-time updates
  const handleExcalidrawChange = useCallback(
    (elements, appState) => {
      // Debounce the updateWhiteboardData call
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = setTimeout(() => {
        // Prepare the data to send
        const data = { elements, appState };

        // Send real-time whiteboard data
        updateWhiteboardData(data, activeClassIDs)
          .then(() => {
            console.log('Real-time whiteboard data sent.');
          })
          .catch(error => {
            console.error('Error sending real-time whiteboard data:', error);
          });
      }, DEBOUNCE_DELAY);
    },
    [updateWhiteboardData, activeClassIDs]
  );

  // Toolbar button handlers
  const toggleLock = () => {
    setIsLocked(prev => !prev);
  };

  const toggleMode = () => {
    setIsIndividual(prev => !prev);
  };

  const handleView = () => {
    // Implement view functionality here
    console.log('View button clicked');
  };

  const handleAddPrompt = () => {
    // Implement add prompt functionality here
    console.log('Add Prompt button clicked');
  };

  // Optionally, you can show a loading indicator while whiteboard data is being fetched
  if (isLoading) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-1/4 p-4 bg-white rounded-lg shadow-lg">
          <p>Loading Whiteboard...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex flex-col bg-white student-tests">
          {/* Header Section with SVG Banner and Date/Time */}
          <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
            {/* Title */}
            <p className="text-3xl font-bold text-center">{title}</p>
            {/* Current Date and Time */}
            <div className="mt-2 text-xs font-bold text-gray-600">
              {getFormattedDate()}
            </div>
          </div>

          {/* Banner Area with Enhanced Shadow */}
          <div
            className="w-full"
            style={{
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Increased shadow size and darkness
            }}
          >
            {/* Banner image with fixed height */}
            <img
              src={PuzzleBanner}
              alt="Puzzle Banner"
              style={{ height: '96px', width: '100%', objectFit: 'cover' }}
            />
          </div>

          {/* Close Button */}
          <div className="absolute top-4 right-4">
            <button
              onClick={handleCloseModal}
              className="text-3xl text-gray-600 hover:text-gray-800 focus:outline-none"
              aria-label="Close Modal"
            >
              <FaTimes />
            </button>
          </div>

          {/* Toolbar */}
          <div className="flex items-center justify-center w-full h-12 px-4 bg-gray-200 border-b">
            <button
              onClick={toggleLock}
              className={`px-4 py-2 mx-2 rounded ${
                isLocked ? 'bg-red-500 text-white' : 'bg-green-500 text-white'
              } hover:bg-opacity-80 focus:outline-none`}
              aria-label={isLocked ? 'Unlock Whiteboard' : 'Lock Whiteboard'}
            >
              {isLocked ? 'Locked' : 'Unlocked'}
            </button>
            
            <button
              onClick={toggleMode}
              className={`px-4 py-2 mx-2 rounded ${
                isIndividual ? 'bg-blue-500' : 'bg-purple-500'
              } text-white hover:bg-opacity-80 focus:outline-none ${
                isLocked ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLocked}
              aria-label={isIndividual ? 'Switch to Group Mode' : 'Switch to Individual Mode'}
            >
              {isIndividual ? 'Individual' : 'Group'}
            </button>
            
            <button
              onClick={handleView}
              className={`px-4 py-2 mx-2 rounded bg-yellow-500 text-white hover:bg-opacity-80 focus:outline-none ${
                isLocked ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLocked}
              aria-label="View"
            >
              View
            </button>
          </div>

          {/* Main Content */}
          <div className="flex flex-col items-center justify-start flex-grow p-6 overflow-auto">
            <Excalidraw
              onChange={handleExcalidrawChange}
              excalidrawAPI={setExcalidrawAPI}
              initialData={initialData} // Use the fetched initialData
              theme="light"
              gridModeEnabled={false}
              zenModeEnabled={false}
              viewModeEnabled={false}
              UIOptions={{
                canvasActions: {
                  loadScene: false,
                  saveToActiveFile: false,
                  export: false,
                  saveAsImage: false,
                },
                libraries: false
              }}
              renderTopRightUI={() => null}
              viewBackgroundColor="#ffffff"
              detectScroll={false}
              handleKeyboardGlobally={false}
              libraryReturnUrl={null}
              autoFocus={true}
              className="w-full h-full"
            />
            {/* Add any additional content or functionality here */}
          </div>
        </div>
      )}
    </>
  );
};

ExcalidrawModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  initialData: PropTypes.object, // Expecting { elements: [...], appState: {...} }
  activeClassIDs: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  onSave: PropTypes.func
};

export default ExcalidrawModal;
