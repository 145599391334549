// src/components/Teachers/Tests/VoxelEditor.jsx

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import InstancedVoxels from './InstancedVoxels';
import Scene from './Scene';
import { Canvas } from '@react-three/fiber';
import { EffectComposer, SSAO } from '@react-three/postprocessing';
import * as THREE from 'three';
import { Stats as DreiStats } from '@react-three/drei';

// Define a list of predefined colors for the toolbar
const COLORS = [
  '#ff0000', // 1 - Red
  '#00ff00', // 2 - Green
  '#0000ff', // 3 - Blue
  '#ffff00', // 4 - Yellow
  '#ff00ff', // 5 - Magenta
  '#00ffff', // 6 - Cyan
  '#ffffff', // 7 - White
  '#000000', // 8 - Black
  '#808080', // 9 - Gray
];

// Define placement sizes
const PLACEMENT_SIZES = [1, 4, 10, 20];

const VoxelEditor = () => {
  const [blocks, setBlocks] = useState([]);
  const [currentColor, setCurrentColor] = useState(COLORS[0]); // Default to first color
  const [placementSize, setPlacementSize] = useState(1); // Default placement size

  // Reference to store the currently selected color index
  const selectedColorIndex = useRef(0);
  const selectedSizeIndex = useRef(0); // Reference for selected placement size

  // Performance Measurement Handlers
  const measureFunction = (name, fn) => {
    return (...args) => {
      performance.mark(`${name}-start`);
      fn(...args);
      performance.mark(`${name}-end`);
      performance.measure(name, `${name}-start`, `${name}-end`);
      const measure = performance.getEntriesByName(name).pop();
      console.log(`${name} took ${measure.duration.toFixed(2)}ms`);
      performance.clearMarks();
      performance.clearMeasures();
    };
  };

  // Add Block Handler with Performance Measurement
  const addBlock = useCallback(measureFunction('addBlock', (x, y, z) => {
    const size = placementSize;
    const halfSize = Math.floor(size / 2);
    const newBlocks = [];

    for (let dx = 0; dx < size; dx++) {
      for (let dy = 0; dy < size; dy++) {
        for (let dz = 0; dz < size; dz++) {
          const newPos = [
            Math.round(x) - halfSize + dx,
            Math.round(y) - halfSize + dy,
            Math.round(z) - halfSize + dz,
          ];

          // Avoid adding a block that's already present
          const exists = blocks.some(
            (block) =>
              block.position[0] === newPos[0] &&
              block.position[1] === newPos[1] &&
              block.position[2] === newPos[2]
          );

          if (!exists) {
            const newBlock = { id: uuidv4(), position: newPos, color: currentColor };
            newBlocks.push(newBlock);
          }
        }
      }
    }

    setBlocks((prev) => [...prev, ...newBlocks]);
    console.log(`Added ${newBlocks.length} blocks.`);
  }), [currentColor, placementSize, blocks]);

  // Remove Block Handler with Performance Measurement
  const removeBlock = useCallback(measureFunction('removeBlock', (posToRemove) => {
    console.log('Attempting to remove block at:', posToRemove);
    setBlocks((prev) =>
      prev.filter((block) => {
        const [bx, by, bz] = block.position;
        const [rx, ry, rz] = posToRemove;
        const isSame = bx === rx && by === ry && bz === rz;
        if (isSame) {
          console.log(`Block Removed: ID=${block.id}, Position=${block.position}, Color=${block.color}`);
        }
        return !isSame;
      })
    );
  }), []);

  // Handle keyboard shortcuts for selecting colors
  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key;
      const index = parseInt(key, 10) - 1;
      if (index >= 0 && index < COLORS.length) {
        setCurrentColor(COLORS[index]);
        selectedColorIndex.current = index;
        console.log(`Color Selected via Keyboard: ${index + 1} (${COLORS[index]})`);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Handle keyboard shortcuts for selecting placement size
  useEffect(() => {
    const handleSizeKeyDown = (e) => {
      const key = e.key;
      const sizeIndex = PLACEMENT_SIZES.indexOf(parseInt(key, 10));
      if (sizeIndex !== -1) {
        setPlacementSize(PLACEMENT_SIZES[sizeIndex]);
        selectedSizeIndex.current = sizeIndex;
        console.log(`Placement Size Selected via Keyboard: ${PLACEMENT_SIZES[sizeIndex]}x${PLACEMENT_SIZES[sizeIndex]}x${PLACEMENT_SIZES[sizeIndex]}`);
      }
    };
    window.addEventListener('keydown', handleSizeKeyDown);
    return () => {
      window.removeEventListener('keydown', handleSizeKeyDown);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative', // Establish positioning context
        width: '100%',
        height: '100%',
      }}
    >
      {/* Center Cursor (Crosshair) */}
      <div
        style={{
          position: 'absolute',
          top: '50%', // Center vertically
          left: '50%', // Center horizontally
          transform: 'translate(-50%, -50%)',
          color: 'black',
          fontSize: '24px',
          pointerEvents: 'none', // Make sure the cursor doesn't block interactions
          zIndex: 1,
        }}
      >
        +
      </div>

      {/* Number Toolbar for Colors */}
      <div
        style={{
          position: 'absolute',
          bottom: 10,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '10px',
          background: 'rgba(255, 255, 255, 0.8)',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 1,
        }}
      >
        {COLORS.map((color, index) => (
          <button
            key={index}
            onClick={() => {
              setCurrentColor(color);
              selectedColorIndex.current = index;
              console.log(`Color Selected via Button: ${index + 1} (${color})`);
            }}
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: color,
              border:
                selectedColorIndex.current === index
                  ? '3px solid blue'
                  : '1px solid black',
              borderRadius: '4px',
              cursor: 'pointer',
              outline: 'none',
            }}
            title={`Select Color ${index + 1}`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Placement Size Toolbar */}
      <div
        style={{
          position: 'absolute',
          bottom: 10,
          right: '10%',
          display: 'flex',
          gap: '10px',
          background: 'rgba(255, 255, 255, 0.8)',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 1,
        }}
      >
        {PLACEMENT_SIZES.map((size, index) => (
          <button
            key={size}
            onClick={() => {
              setPlacementSize(size);
              selectedSizeIndex.current = index;
              console.log(`Placement Size Selected via Button: ${size}x${size}x${size}`);
            }}
            style={{
              width: '50px',
              height: '40px',
              backgroundColor: placementSize === size ? '#d3d3d3' : '#f0f0f0',
              border:
                selectedSizeIndex.current === index
                  ? '3px solid blue'
                  : '1px solid black',
              borderRadius: '4px',
              cursor: 'pointer',
              outline: 'none',
            }}
            title={`Select Placement Size ${size}x${size}x${size}`}
          >
            {size}³
          </button>
        ))}
      </div>

      {/* 3D Canvas */}
      <Canvas
        camera={{ position: [0, 2, 5], fov: 75 }}
        shadows
        style={{ background: '#87ceeb', width: '100%', height: '100%' }}
        onCreated={({ gl, camera }) => {
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = THREE.PCFSoftShadowMap;
          camera.lookAt(0, 0, 0);
        }}
      >
        {/* Post-Processing Effects */}
        <EffectComposer>
          <SSAO
            samples={31}
            radius={20}
            intensity={30}
            luminanceInfluence={0.5}
            color="black"
          />
        </EffectComposer>

        {/* Performance Stats */}
        <DreiStats showPanel={0} />

        <Scene
          blocks={blocks}
          addBlock={addBlock}
          removeBlock={removeBlock}
          currentColor={currentColor}
        />
      </Canvas>
    </div>
  );
};

export default VoxelEditor;