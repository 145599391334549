// src/components/Teachers/Slideshow/SlideCreator.js

import React, { useState, useEffect } from 'react';
import Button from '../ui/Button';

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Initialize slides with title, subtitle, and theme
  const [slides, setSlides] = useState([
    {
      id: Date.now(),
      title: 'New Slide',
      subtitle: '',
      theme: 'castle', // Default theme
    },
  ]);
  const [selectedSlideId, setSelectedSlideId] = useState(null);

  // Set the first slide as selected when slides change
  useEffect(() => {
    if (slides.length > 0 && selectedSlideId === null) {
      setSelectedSlideId(slides[0].id);
    }
  }, [slides, selectedSlideId]);

  // Function to add a new slide
  const addSlide = () => {
    const newSlide = {
      id: Date.now(),
      title: 'New Slide', // Corrected property name
      subtitle: '',
      theme: 'castle',
    };
    setSlides([...slides, newSlide]);
    setSelectedSlideId(newSlide.id);
  };

  // Function to remove a slide by ID
  const removeSlide = (id) => {
    const updatedSlides = slides.filter((s) => s.id !== id);
    setSlides(updatedSlides);
    if (updatedSlides.length > 0) {
      setSelectedSlideId(updatedSlides[0].id);
    } else {
      addSlide();
    }
  };

  // Handlers for changing title, subtitle, and theme
  const handleTitleChange = (id, newTitle) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, title: newTitle } : s
      )
    );
  };

  const handleSubtitleChange = (id, newSubtitle) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, subtitle: newSubtitle } : s
      )
    );
  };

  const handleThemeChange = (id, newTheme) => {
    setSlides(
      slides.map((s) =>
        s.id === id ? { ...s, theme: newTheme } : s
      )
    );
  };

  // Find the currently selected slide
  const selectedSlide = slides.find((s) => s.id === selectedSlideId);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
      <div className="flex w-11/12 max-h-full overflow-hidden bg-white rounded-lg shadow-lg md:w-3/4 lg:w-2/3">
        
        {/* **Left Panel (Mini Slide Previews)** */}
        <div className="flex flex-col w-1/4 p-4 overflow-y-auto border-r">
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-lg font-medium">Slides</h4>
            <button
              type="button"
              onClick={addSlide}
              className="flex items-center justify-center w-8 h-8 text-white bg-green-500 rounded-full hover:bg-green-600 focus:outline-none"
              aria-label="Add Slide"
            >
              +
            </button>
          </div>
          <ul className="flex-1 space-y-2">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => setSelectedSlideId(slide.id)}
                className={`p-2 cursor-pointer rounded border ${
                  selectedSlideId === slide.id
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:bg-gray-100'
                }`}
              >
                <div className="flex items-center">
                  {/* Mini Slide Preview */}
                  <div className="flex items-center justify-center w-16 h-16 mr-2 overflow-hidden border rounded bg-gray-50">
                    <p className="px-1 text-xs text-center">
                      {slide.title.length > 20
                        ? `${slide.title.substring(0, 20)}...`
                        : slide.title}
                    </p>
                  </div>
                  <span className="flex-1 text-sm">
                    Slide {index + 1}
                  </span>
                  {slides.length > 1 && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSlide(slide.id);
                      }}
                      className="ml-2 text-red-500 hover:text-red-700"
                      aria-label={`Remove Slide ${index + 1}`}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* **Right Panel (Slide Editor)** */}
        <div className="w-3/4 p-6 overflow-y-auto">
          {/* **Header with Title and Theme Selector** */}
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold">
              {selectedSlide ? `Edit Slide ${slides.indexOf(selectedSlide) + 1}` : 'Create a New Slide'}
            </h3>
            {selectedSlide && (
              <div className="w-1/3">
                <label className="block mb-1 text-sm font-medium text-gray-700">
                  Theme
                </label>
                <select
                  value={selectedSlide.theme}
                  onChange={(e) =>
                    handleThemeChange(selectedSlide.id, e.target.value)
                  }
                  className="w-full px-3 py-2 bg-white border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                >
                  <option value="castle">Castle</option>
                  <option value="train">Train</option>
                  <option value="forest">Forest</option>
                  {/* Add more placeholder themes as needed */}
                </select>
              </div>
            )}
            <Button
              onClick={onClose}
              variant="ghost"
              className="ml-4 text-2xl leading-none"
              aria-label="Close Slide Creator"
            >
              &times;
            </Button>
          </div>
          
          {selectedSlide && (
            <div className="space-y-6">
              {/* Placeholder for Image/SVG */}
              <div className="flex items-center justify-center w-full p-4 bg-gray-100 border rounded-md h-96">
                <span className="text-gray-500">Image/SVG Placeholder</span>
              </div>

              {/* Title Text Box */}
              <div className="p-4 border rounded-md">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Text 1
                </label>
                <input
                  type="text"
                  value={selectedSlide.title}
                  onChange={(e) =>
                    handleTitleChange(selectedSlide.id, e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter title"
                />
              </div>

              {/* Subtitle Text Box */}
              <div className="p-4 border rounded-md">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                 Text 2
                </label>
                <input
                  type="text"
                  value={selectedSlide.subtitle}
                  onChange={(e) =>
                    handleSubtitleChange(selectedSlide.id, e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter subtitle"
                />
              </div>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end mt-6 space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-300 rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                onPopSlide && onPopSlide(slides);
                onClose();
              }}
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
            >
              Save Slides
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideCreator;
