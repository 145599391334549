// Timer.js

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Rnd } from 'react-rnd';
import { ref as dbRef, onValue, update } from 'firebase/database';
import { rtdb } from '../../../firebase'; // Adjust the path based on your project structure
import { Minus, Plus } from 'lucide-react';
import debounce from 'lodash.debounce';

const MINIMIZED_SIZE = 60; // Define a constant for minimized size

const Timer = React.memo(({ classId }) => {
  // State declarations
  const [dimensions, setDimensions] = useState({
    width: 300,
    height: 300,
    x: 100,
    y: 100,
  });

  const prevDimensionsRef = useRef({
    width: 300,
    height: 300,
    x: 100,
    y: 100,
  });

  const [isMinimized, setIsMinimized] = useState(false);
  const [time, setTime] = useState(60);
  const [isRunning, setIsRunning] = useState(false);
  const [targetTime, setTargetTime] = useState(60);
  const [isEditing, setIsEditing] = useState(false);
  const [editTime, setEditTime] = useState('01:00');
  const [error, setError] = useState('');
  const [fontSize, setFontSize] = useState(40);
  const intervalRef = useRef(null);
  const inputRef = useRef(null);
  const timeDisplayRef = useRef(null);
  const containerRef = useRef(null);

  const timerRef = dbRef(rtdb, `timers/${classId}`);

  // Refs for click vs drag detection
  const mouseDownTimeRef = useRef(0);
  const mouseDownPosRef = useRef({ x: 0, y: 0 });

  // Memoize styles for performance
  const styles = useMemo(() => ({
    timerContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: isMinimized ? '#4f46e5' : 'rgba(255, 255, 255, 0.95)', // Purple when minimized
      borderRadius: isMinimized ? '50%' : '16px',
      boxShadow: 'none', // Removed shadow
      justifyContent: 'space-between',
      overflow: 'hidden',
      boxSizing: 'border-box',
      transition: 'border-radius 0.5s ease, background-color 1s ease', // Increased transition durations
      position: 'relative', // For positioning the emoji
    },
    timerHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '8px',
      backgroundColor: 'transparent',
      color: 'white',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      cursor: 'move',
      userSelect: 'none',
      flexShrink: 0,
    },
    timerControls: {
      display: 'flex',
      alignItems: 'center',
    },
    controlButton: {
      background: 'none',
      border: 'none',
      color: 'black',
      marginLeft: '8px',
      cursor: 'pointer',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      transition: 'transform 0.2s',
    },
    controlButtonHover: {
      transform: 'scale(1.1)',
    },
    fontSizeControls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '16px',
    },
    fontButton: {
      background: 'none',
      border: '1px solid #838585',
      borderRadius: '4px',
      color: '#838585',
      margin: '0 8px',
      cursor: 'pointer',
      padding: '4px 8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      transition: 'background-color 0.2s, color 0.2s',
    },
    timerBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center', // Center content when minimized
      flex: 1,
      padding: '16px',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    timeDisplayContainer: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '50px',
      textAlign: 'center',
    },
    timeDisplay: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      wordBreak: 'break-word',
      flexShrink: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: `${fontSize}px`,
      transition: 'font-size 0.2s ease',
      display: isMinimized ? 'none' : 'block', // Hide when minimized
    },
    timeInput: {
      fontSize: '1.5em',
      textAlign: 'center',
      border: '2px solid #838585',
      borderRadius: '8px',
      padding: '4px',
      width: '100%',
      boxSizing: 'border-box',
      display: isMinimized ? 'none' : 'block', // Hide when minimized
    },
    errorText: {
      color: 'red',
      fontSize: '0.9em',
      marginTop: '8px',
      textAlign: 'center',
      flexShrink: 0,
      display: isMinimized ? 'none' : 'block', // Hide when minimized
    },
    timerButtons: {
      display: isMinimized ? 'none' : 'flex', // Hide when minimized
      gap: '8px',
      marginTop: '16px',
      width: '100%',
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexShrink: 0,
      paddingBottom: '16px',
    },
    startPauseBtn: {
      flex: '1 1 45%',
      padding: '8px 0',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      backgroundColor: isRunning ? '#3b82f6' : '#10b981',
      color: 'white',
      fontSize: '0.9em',
      minWidth: '60px',
      textAlign: 'center',
      transition: 'background-color 0.2s',
    },
    resetBtn: {
      flex: '1 1 45%',
      padding: '8px 0',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      backgroundColor: '#3b82f6',
      color: 'white',
      fontSize: '0.9em',
      minWidth: '60px',
      textAlign: 'center',
      transition: 'background-color 0.2s',
    },
    floatingCircle: {
      display: isMinimized ? 'flex' : 'none', // Show only when minimized
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent', // Background handled by timerContainer
      borderRadius: '50%',
      boxShadow: 'none', // Removed shadow
      cursor: 'grab',
      color: 'white',
      fontSize: '1.2em',
      userSelect: 'none',
      boxSizing: 'border-box',
      padding: 0,
      transition: 'opacity 0.3s ease',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    minimizedEmoji: {
      fontSize: '1.2em',
      pointerEvents: 'none',
      margin: '0',
    },
  }), [fontSize, isRunning, isMinimized]);

  // Debounce the Firebase update for performance
  const debouncedUpdate = useMemo(() => debounce((newState) => {
    update(timerRef, newState);
  }, 500), [timerRef]);

  // Load timer state from RTDB on mount
  useEffect(() => {
    const unsubscribe = onValue(timerRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTime(data.time !== undefined ? data.time : 60);
        setIsRunning(data.isRunning || false);
        setTargetTime(data.targetTime || 60);
        setFontSize(data.fontSize !== undefined ? data.fontSize : 40);
      }
    });

    return () => unsubscribe();
  }, [timerRef]);

  // Sync timer state to RTDB with debouncing
  useEffect(() => {
    debouncedUpdate({ time, isRunning, targetTime, fontSize });
  }, [time, isRunning, targetTime, fontSize, debouncedUpdate]);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  // Handle timer running
  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTime((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            setIsRunning(false);
            return 0;
          }
        });
      }, 1000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isRunning]);

  // Format time in HH:MM:SS
  const formatTime = useCallback((totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor((totalSeconds % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }, []);

  // Parse time string to seconds
  const parseTime = useCallback((timeString) => {
    const parts = timeString.split(':').map(Number);
    if (parts.length === 3) {
      const [hours, minutes, seconds] = parts;
      if (
        isNaN(hours) ||
        isNaN(minutes) ||
        isNaN(seconds) ||
        hours < 0 ||
        minutes < 0 ||
        minutes > 59 ||
        seconds < 0 ||
        seconds > 59
      ) {
        return null;
      }
      return hours * 3600 + minutes * 60 + seconds;
    } else if (parts.length === 2) {
      const [minutes, seconds] = parts;
      if (
        isNaN(minutes) ||
        isNaN(seconds) ||
        minutes < 0 ||
        seconds < 0 ||
        seconds > 59
      ) {
        return null;
      }
      return minutes * 60 + seconds;
    } else if (parts.length === 1) {
      const [seconds] = parts;
      if (isNaN(seconds) || seconds < 0) {
        return null;
      }
      return seconds;
    }
    return null;
  }, []);

  // Event handlers
  const toggleStartPause = useCallback(() => {
    if (time === 0) return;
    setIsRunning((prev) => !prev);
  }, [time]);

  const resetTimer = useCallback(() => {
    setIsRunning(false);
    setTime(targetTime);
  }, [targetTime]);

  const minimize = useCallback(() => {
    if (!isMinimized) {
      // Store current dimensions in ref
      prevDimensionsRef.current = { ...dimensions };

      // Calculate current center
      const centerX = dimensions.x + dimensions.width / 2;
      const centerY = dimensions.y + dimensions.height / 2;

      // Set minimized size to a constant value
      const minimizedSize = MINIMIZED_SIZE;

      // Calculate new x and y to keep center the same
      const newX = centerX - minimizedSize / 2;
      const newY = centerY - minimizedSize / 2;

      setDimensions({
        width: minimizedSize,
        height: minimizedSize,
        x: newX,
        y: newY,
      });
      setIsMinimized(true);
    }
  }, [isMinimized, dimensions]);

  const restore = useCallback(() => {
    if (isMinimized) {
      const prev = prevDimensionsRef.current;

      // Calculate center based on minimized dimensions
      const centerX = dimensions.x + dimensions.width / 2;
      const centerY = dimensions.y + dimensions.height / 2;

      // Calculate restored x and y to keep center the same
      const newX = centerX - prev.width / 2;
      const newY = centerY - prev.height / 2;

      setDimensions({
        width: prev.width,
        height: prev.height,
        x: newX,
        y: newY,
      });
      setIsMinimized(false);
    }
  }, [isMinimized, dimensions]);

  const handleTimeClick = useCallback(() => {
    if (!isMinimized) { // Allow editing only when not minimized
      setIsEditing(true);
      setEditTime(formatTime(time));
      setError('');
    }
  }, [time, formatTime, isMinimized]);

  const handleTimeChange = useCallback((e) => {
    setEditTime(e.target.value);
  }, []);

  const handleTimeSubmit = useCallback(() => {
    const parsedTime = parseTime(editTime);
    if (parsedTime !== null) {
      setTargetTime(parsedTime);
      setTime(parsedTime);
      setIsRunning(false);
      setIsEditing(false);
      setError('');
    } else {
      setError('Invalid time format. Please use HH:MM:SS, MM:SS, or SS.');
    }
  }, [editTime, parseTime]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      handleTimeSubmit();
    }
  }, [handleTimeSubmit]);

  // Focus the input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const decreaseFontSize = useCallback(() => {
    setFontSize((prev) => Math.max(prev - 50, 40));
  }, []);

  const increaseFontSize = useCallback(() => {
    setFontSize((prev) => Math.min(prev + 50, 600));
  }, []);

  // Handle Rnd resize stop to update dimensions
  const handleResizeStop = useCallback((e, direction, ref, delta, position) => {
    setDimensions({
      width: parseInt(ref.style.width, 10),
      height: parseInt(ref.style.height, 10),
      x: position.x,
      y: position.y,
    });
  }, []);

  // Handle Rnd resize to update dimensions in real-time
  const handleResize = useCallback((e, direction, ref, delta, position) => {
    setDimensions({
      width: parseInt(ref.style.width, 10),
      height: parseInt(ref.style.height, 10),
      x: position.x,
      y: position.y,
    });
  }, []);

  // Handle Rnd drag stop to update position
  const handleDragStop = useCallback((e, d) => {
    setDimensions((prev) => ({
      ...prev,
      x: d.x,
      y: d.y,
    }));
  }, []);

  // Click vs Drag logic for minimized floating button
  const handleMouseDown = useCallback((e) => {
    mouseDownTimeRef.current = Date.now();
    mouseDownPosRef.current = { x: e.clientX, y: e.clientY };
  }, []);

  const handleMouseUp = useCallback((e) => {
    const clickThreshold = 200; // milliseconds
    const moveThreshold = 5; // pixels

    const mouseUpTime = Date.now();
    const timeDiff = mouseUpTime - mouseDownTimeRef.current;

    const deltaX = Math.abs(e.clientX - mouseDownPosRef.current.x);
    const deltaY = Math.abs(e.clientY - mouseDownPosRef.current.y);
    const moveDiff = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (timeDiff < clickThreshold && moveDiff < moveThreshold) {
      restore();
    }
    // Else, it's a drag; do nothing
  }, [restore]);

  return (
    <Rnd
      size={{ width: dimensions.width, height: dimensions.height }}
      position={{ x: dimensions.x, y: dimensions.y }}
      onDragStop={handleDragStop}
      onResize={handleResize} // Real-time updates
      onResizeStop={handleResizeStop}
      minWidth={isMinimized ? MINIMIZED_SIZE : 200} // Use constant for minimized
      minHeight={isMinimized ? MINIMIZED_SIZE : 200} // Use constant for minimized
      bounds="window"
      aspectRatio={isMinimized ? 1 : undefined} // Lock aspect ratio when minimized
      style={{
        zIndex: 1000,
        overflow: 'hidden',
        cursor: isMinimized ? 'grab' : 'default',
        padding: 0,
        // Removed transition to improve drag and resize performance
      }}
      enableResizing={!isMinimized}
      disableDragging={false} // Allow dragging both when minimized and maximized
      className="timer-rnd"
    >
      <div
        style={styles.timerContainer}
        onMouseDown={isMinimized ? handleMouseDown : undefined}
        onMouseUp={isMinimized ? handleMouseUp : undefined}
      >
        {/* Header */}
        <div style={styles.timerHeader}>
          <div style={styles.timerControls}>
            {/* Conditionally Render Minimize Button */}
            {!isMinimized && (
              <button
                onClick={minimize}
                style={styles.controlButton}
                title="Minimize"
                aria-label="Minimize Timer"
              >
                <Minus size={16} />
              </button>
            )}
            {/* Removed Close Button */}
          </div>
        </div>

        {/* Body */}
        <div style={styles.timerBody}>
          {isEditing && !isMinimized ? (
            <input
              type="text"
              value={editTime}
              onChange={handleTimeChange}
              onBlur={handleTimeSubmit}
              onKeyDown={handleKeyDown}
              style={styles.timeInput}
              ref={inputRef}
              placeholder="HH:MM:SS"
              aria-label="Edit Timer"
            />
          ) : (
            !isMinimized && (
              <div
                style={styles.timeDisplayContainer}
                onClick={handleTimeClick}
                title="Click to edit"
                ref={containerRef}
              >
                <div
                  style={styles.timeDisplay}
                  ref={timeDisplayRef}
                >
                  {formatTime(time)}
                </div>
              </div>
            )
          )}
          {error && !isMinimized && <div style={styles.errorText}>{error}</div>}

          {/* Conditionally Render Font Size Controls and Timer Buttons */}
          {!isMinimized && (
            <>
              {/* Font Size Controls */}
              <div style={styles.fontSizeControls}>
                <button
                  onClick={decreaseFontSize}
                  style={styles.fontButton}
                  title="Decrease Font Size"
                  aria-label="Decrease Font Size"
                >
                  <Minus size={16} />
                </button>
                <button
                  onClick={increaseFontSize}
                  style={styles.fontButton}
                  title="Increase Font Size"
                  aria-label="Increase Font Size"
                >
                  <Plus size={16} />
                </button>
              </div>

              {/* Timer Buttons */}
              <div style={styles.timerButtons}>
                <button
                  onClick={toggleStartPause}
                  style={styles.startPauseBtn}
                  aria-label="Start or Pause Timer"
                >
                  {isRunning ? 'Pause' : 'Start'}
                </button>
                <button
                  onClick={resetTimer}
                  style={styles.resetBtn}
                  aria-label="Reset Timer"
                >
                  Reset
                </button>
              </div>
            </>
          )}
        </div>

        {/* Clock Timer Emoji - Visible Only When Minimized */}
        {isMinimized && (
          <div style={styles.floatingCircle}>
            <div style={styles.minimizedEmoji}>
              ⏰
            </div>
          </div>
        )}
      </div>
    </Rnd>
  );
});

export default Timer;
