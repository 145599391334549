// src/components/Teachers/Slideshow/SlideshowApp.js

import React, { useState, useEffect, useRef } from 'react';
import Button from '../ui/Button';
import QuizCreator from './SlidesCreator'; // Import the QuizCreator component
import EditQuizModal from './EditSlidesModal'; // Import the EditQuizModal component
import LaunchQuiz from './LaunchQuiz'; // Import the LaunchQuiz component
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported

// Function to add ordinal suffix to the day
const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

// Function to format the current date and time
const getFormattedDate = () => {
  const now = new Date();
  const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
  const month = now.toLocaleDateString('en-US', { month: 'long' });
  const day = getOrdinal(now.getDate());
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
  return `${weekday} the ${day} of ${month} ${formattedTime}`;
};

// Example quiz data (without questions)
const initialQuizzes = [
  {
    id: 1,
    title: 'Math Basics',
    description: 'A quiz on basic mathematics concepts.',
  },
  {
    id: 2,
    title: 'Science Fundamentals',
    description: 'Test your knowledge on basic science topics.',
  },
  // Add more quiz objects as needed
];

// Define the SlideshowApp component
const SlideshowApp = ({ onClose }) => {
  // Fullscreen State
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isQuizCreatorOpen, setIsQuizCreatorOpen] = useState(false); // State for QuizCreator
  const [isEditQuizOpen, setIsEditQuizOpen] = useState(false); // State for EditQuizModal
  const [currentQuiz, setCurrentQuiz] = useState(null); // State for the quiz being edited
  const [launchedQuizId, setLaunchedQuizId] = useState(null); // State for the launched quiz ID
  const [launchedQuizData, setLaunchedQuizData] = useState(null); // State for the launched quiz data
  const slideshowRef = useRef(null);

  // Quizzes State
  const [quizzes, setQuizzes] = useState(initialQuizzes);

  // Function to toggle fullscreen
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (slideshowRef.current.requestFullscreen) {
        slideshowRef.current.requestFullscreen();
      } else if (slideshowRef.current.webkitRequestFullscreen) {
        /* Safari */
        slideshowRef.current.webkitRequestFullscreen();
      } else if (slideshowRef.current.msRequestFullscreen) {
        /* IE11 */
        slideshowRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  // Handle fullscreen change events
  useEffect(() => {
    const handleFullscreenChange = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;
      setIsFullscreen(!!fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Handlers for QuizCreator Modal
  const openQuizCreator = () => {
    setIsQuizCreatorOpen(true);
  };

  const closeQuizCreator = () => {
    setIsQuizCreatorOpen(false);
  };

  // Handlers for EditQuizModal
  const openEditQuiz = (quiz) => {
    setCurrentQuiz(quiz);
    setIsEditQuizOpen(true);
  };

  const closeEditQuiz = () => {
    setIsEditQuizOpen(false);
    setCurrentQuiz(null);
  };

  const handleUpdateQuiz = (updatedQuiz) => {
    setQuizzes((prevQuizzes) =>
      prevQuizzes.map((quiz) => (quiz.id === updatedQuiz.id ? updatedQuiz : quiz))
    );
    closeEditQuiz();
  };

  // Handler for Launch Quiz
  const handleLaunchQuiz = (quizId) => {
    setLaunchedQuizId(quizId);
    setLaunchedQuizData(null); // Reset any launchedQuizData
  };

  // Handler for Pop Quiz
  const handlePopQuiz = (quizData) => {
    setLaunchedQuizData(quizData);
    setLaunchedQuizId(null); // Reset any launchedQuizId
  };

  // Handler to close LaunchQuiz
  const closeLaunchQuiz = () => {
    setLaunchedQuizId(null);
    setLaunchedQuizData(null);
  };

  // Handler for Search Quiz (You can implement the search functionality as needed)
  const handleSearchQuiz = () => {
    // Implement search functionality here
    console.log('Search Quiz Clicked');
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-black bg-opacity-50">
      {/* **Modal Container with Fullscreen Dimensions** */}
      <div
        ref={slideshowRef}
        className="flex flex-col w-full h-full bg-white rounded-none shadow-lg"
      >
        {/* **Header** */}
        <header className="relative flex flex-col items-center justify-center flex-shrink-0 p-4 border-b">
          {/* Title */}
          <h2 className="text-2xl font-bold">Slides</h2>
          {/* Current Date and Time */}
          <div className="mt-2 text-sm font-medium text-gray-600">
            {getFormattedDate()}
          </div>
          {/* Close Button */}
          <Button
            onClick={onClose}
            variant="ghost"
            className="absolute text-2xl text-gray-600 top-4 right-4 hover:text-gray-800"
            aria-label="Close Slideshow App"
          >
            <FaTimes />
          </Button>
          {/* Fullscreen Toggle Button */}
          <Button
            onClick={toggleFullscreen}
            variant="ghost"
            className="absolute text-lg text-gray-600 top-4 left-4 hover:text-gray-800"
            aria-label="Toggle Fullscreen"
          >
            {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          </Button>
        </header>

        {/* **Banner Area** */}
        <div
          className="w-full"
          style={{
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Adjusted shadow for consistency
          }}
        >
          {/* Banner image with fixed height */}
          <img
            src={PuzzleBanner}
            alt="Banner"
            className="object-cover w-full h-24"
          />
        </div>

        {/* **Main Content Area** */}
        <div className="flex-1 overflow-auto">
          {launchedQuizId || launchedQuizData ? (
            // **LaunchQuiz Component**
            <LaunchQuiz
              quizId={launchedQuizId}
              quizData={launchedQuizData}
              onClose={closeLaunchQuiz}
            />
          ) : (
            // **Quiz Management Sections**
            <div className="flex flex-col h-full">
              {/* **Horizontal Bar with Tiles** */}
              <div className="flex justify-start p-4 bg-white shadow-md">
                {/* Create Quiz Tile */}
                <button
                  onClick={openQuizCreator}
                  className="flex items-center justify-center flex-1 h-40 m-2 text-blue-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
                >
                  {/* You can replace the text with an icon if desired */}
                  <span className="text-xl font-semibold">Create Slides</span>
                </button>

                {/* Search Quiz Tile */}
                <button
                  onClick={handleSearchQuiz}
                  className="flex items-center justify-center flex-1 h-40 m-2 text-green-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
                >
                  {/* You can replace the text with an icon if desired */}
                  <span className="text-xl font-semibold">Search Slides</span>
                </button>
              </div>

              {/* **Your Quizzes Section** */}
              <section className="flex-1 p-4 overflow-auto">
                <h3 className="mb-4 text-xl font-bold">Your Slides</h3>
                {/* Quizzes List */}
                <div className="grid grid-cols-1 gap-4">
                  {quizzes.map((quiz) => (
                    <div
                      key={quiz.id}
                      className="flex flex-col p-4 bg-gray-100 rounded-lg shadow"
                    >
                      <h4 className="text-lg font-semibold">{quiz.title}</h4>
                      <p className="text-sm text-gray-600">{quiz.description}</p>
                      <div className="flex mt-4 space-x-2">
                        {/* Edit Button */}
                        <Button
                          onClick={() => openEditQuiz(quiz)}
                          variant="primary"
                          className="px-4 py-2 text-sm text-white bg-yellow-500 rounded hover:bg-yellow-600"
                        >
                          Edit
                        </Button>
                        {/* Launch Button */}
                        <Button
                          onClick={() => handleLaunchQuiz(quiz.id)}
                          variant="primary"
                          className="px-4 py-2 text-sm text-white bg-green-500 rounded hover:bg-green-600"
                        >
                          Launch
                        </Button>
                      </div>
                    </div>
                  ))}
                  {/* Add more quiz items dynamically */}
                </div>
              </section>
            </div>
          )}
        </div>

        {/* **QuizCreator Modal** */}
        {isQuizCreatorOpen && (
          <QuizCreator onClose={closeQuizCreator} onPopQuiz={handlePopQuiz} />
        )}

        {/* **EditQuizModal Modal** */}
        {isEditQuizOpen && currentQuiz && (
          <EditQuizModal
            quiz={currentQuiz}
            onClose={closeEditQuiz}
            onSave={handleUpdateQuiz}
          />
        )}
      </div>
    </div>
  );
};

export default SlideshowApp;
