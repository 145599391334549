// src/components/Students/apps/StudentYourHive.js

import React, { useState, useEffect } from 'react';
import { ref, onValue, off, set } from 'firebase/database';
import { rtdb } from '../../../firebase'; // Adjust the import path if necessary
import { useStudentManager } from '../../../contexts/Studentmanager'; // Import useStudentManager

const StudentYourHive = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState('outfits');

  // Array of 50 themed costume names
  const outfits = [
    'Pirate Captain',
    'Space Explorer',
    'Wizard Apprentice',
    'Superhero',
    'Knight in Shining Armor',
    'Fairy Queen',
    'Ninja Master',
    'Princess Charming',
    'Vampire Lord',
    'Robot Commander',
    'Zombie Hunter',
    'Medieval Archer',
    'Alien Ambassador',
    'Steampunk Engineer',
    'Samurai Warrior',
    'Mermaid Princess',
    'Ghostly Apparition',
    'Dragon Rider',
    'Circus Ringmaster',
    'Ancient Pharaoh',
    'Cyberpunk Hacker',
    'Ancient Greek Hero',
    'Wild West Sheriff',
    'Mystic Sorcerer',
    'Explorer Adventurer',
    'Dinosaur Tamer',
    'Underwater Diver',
    'Elven Ranger',
    'Time Traveler',
    'Haunted Witch',
    'Gladiator Champion',
    'Shadow Assassin',
    'Galactic Pilot',
    'Jungle Scout',
    'Mythical Unicorn',
    'Ice Queen',
    'Fire Dancer',
    'Shadow Ninja',
    'Forest Guardian',
    'Robot Technician',
    'Samurai Sensei',
    'Pirate Navigator',
    'Space Marine',
    'Wizard of Light',
    'Cyber Samurai',
    'Stealth Operative',
    'Ancient Oracle',
    'Mystic Druid',
    'Galactic Emperor',
    'Time Mage'
  ];

  // Array of achievements with XP and locked status
  const achievements = [
    { description: 'Wrote a story over 500 words', xp: 100, locked: false },
    { description: 'Scored 1000 points in Math Quiz', xp: 150, locked: false },
    { description: 'Achieved a typing speed of 60 WPM', xp: 120, locked: false },
    { description: 'Completed 10 coding challenges', xp: 200, locked: true },
    { description: 'Participated in 5 classroom discussions', xp: 80, locked: false },
    { description: 'Read 20 books this semester', xp: 160, locked: true },
    { description: 'Completed all homework assignments on time', xp: 140, locked: false },
    { description: 'Achieved a perfect score in Science Project', xp: 180, locked: false },
    { description: 'Helped a peer understand a difficult concept', xp: 90, locked: true },
    { description: 'Presented a project to the class successfully', xp: 130, locked: false },
    { description: 'Mastered basic algebra', xp: 110, locked: true },
    { description: 'Built a simple website', xp: 170, locked: false },
    { description: 'Completed a community service project', xp: 190, locked: true },
    { description: 'Learned to play a new instrument', xp: 100, locked: false },
    { description: 'Completed a physical fitness challenge', xp: 120, locked: false },
    { description: 'Designed a creative art project', xp: 140, locked: true },
    { description: 'Improved reading comprehension skills', xp: 160, locked: false },
    { description: 'Achieved full attendance for the month', xp: 80, locked: true },
    { description: 'Solved a complex puzzle', xp: 150, locked: false },
    { description: 'Participated in a school competition', xp: 180, locked: true },
    // Add more achievements as needed
  ];

  // State variables for user XP and level
  const [totalXP, setTotalXP] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);

  // State for selected outfit
  const [selectedOutfit, setSelectedOutfit] = useState('');

  // Get user info from context
  const { studentInfo } = useStudentManager();
  const { classId, studentName } = studentInfo || {};

  // Constants for level calculation
  const MAX_LEVEL = 250;
  const BASE_XP = 100;
  const XP_INCREMENT_RATIO = 1.1; // 30% increase per level

  // Precompute XP required for each level up to MAX_LEVEL
  const computeXpPerLevel = () => {
    const xpPerLevel = [BASE_XP];
    for (let i = 1; i < MAX_LEVEL; i++) {
      const nextXp = Math.round(xpPerLevel[i - 1] * XP_INCREMENT_RATIO);
      xpPerLevel.push(nextXp);
    }
    return xpPerLevel;
  };

  const xpPerLevel = computeXpPerLevel();

  // Fetch totalXP and selectedOutfit from RTDB
  useEffect(() => {
    if (!classId || !studentName) return;

    const xpRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}/totalxp`);
    const outfitRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}/selectedOutfit`);

    const handleXpChange = (snapshot) => {
      const xp = snapshot.val() || 0;
      setTotalXP(xp);
    };

    const handleOutfitChange = (snapshot) => {
      const outfit = snapshot.val() || '';
      setSelectedOutfit(outfit);
    };

    onValue(xpRef, handleXpChange);
    onValue(outfitRef, handleOutfitChange);

    // Cleanup listeners on unmount
    return () => {
      off(xpRef, 'value', handleXpChange);
      off(outfitRef, 'value', handleOutfitChange);
    };
  }, [classId, studentName]);

  // Compute current level based on totalXP
  useEffect(() => {
    let accumulatedXP = 0;
    let level = 0;
    while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= totalXP) {
      accumulatedXP += xpPerLevel[level];
      level++;
    }
    setCurrentLevel(level);
  }, [totalXP, xpPerLevel]);

  // Determine number of outfits unlocked (1 new outfit every 5 levels)
  const outfitsUnlocked = Math.floor(currentLevel / 5);
  const availableOutfits = outfits.slice(0, outfitsUnlocked > 0 ? outfitsUnlocked : 1);

  // Handle outfit selection
  const handleSelectOutfit = (outfit) => {
    setSelectedOutfit(outfit);
    if (classId && studentName) {
      const outfitRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}/selectedOutfit`);
      set(outfitRef, outfit)
        .then(() => {
          console.log('Outfit updated successfully.');
        })
        .catch((error) => {
          console.error('Error updating outfit:', error);
        });
    }
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-white student-tests">
      {/* Title */}
      <div className="w-full py-4 text-center">
        <p className="mb-6 text-3xl font-bold">Progress</p>
      </div>

      {/* Tabs Navigation */}
      <div className="flex justify-center mb-4">
        <button
          className={`px-4 py-2 mr-2 rounded ${
            activeTab === 'outfits' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('outfits')}
        >
          Outfits
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'achievements' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('achievements')}
        >
          Achievements
        </button>
      </div>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-start flex-grow w-full p-6 overflow-auto">
        {activeTab === 'outfits' && (
          <div className="w-full">
            <h2 className="mb-4 text-2xl font-semibold">Customize Your Outfits</h2>
            <p className="mb-4 text-gray-600">
              Level: {currentLevel} {currentLevel >= 50 ? '(Max Level)' : ''}
            </p>
            <p className="mb-6 text-gray-600">
              Outfits Unlocked: {outfitsUnlocked > 0 ? outfitsUnlocked : 1} / {Math.floor(MAX_LEVEL / 5)}
            </p>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
              {outfits.map((costume, index) => {
                const isUnlocked = index < (outfitsUnlocked > 0 ? outfitsUnlocked : 1);
                const isSelected = selectedOutfit === costume;
                const unlockLevel = (index + 1) * 5;

                return (
                  <div
                    key={index}
                    className={`flex flex-col items-center justify-center h-32 bg-gray-100 rounded shadow cursor-pointer relative p-2 ${
                      isUnlocked ? 'hover:bg-blue-200' : 'bg-gray-300 cursor-not-allowed'
                    }`}
                    onClick={() => {
                      if (isUnlocked) handleSelectOutfit(costume);
                    }}
                  >
                    <span className="px-2 text-center">{costume}</span>
                    {!isUnlocked && (
                      <span className="mt-2 text-xs text-gray-600">
                        Unlocked at level {unlockLevel}
                      </span>
                    )}
                    {isSelected && isUnlocked && (
                      <span className="absolute px-2 text-xs text-white transform -translate-x-1/2 bg-green-500 rounded bottom-2 left-1/2">
                        Selected
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {activeTab === 'achievements' && (
          <div className="w-full">
            <h2 className="mb-4 text-2xl font-semibold">Your Achievements</h2>
            <ul className="w-full space-y-2">
              {achievements.map((achievement, index) => (
                <li
                  key={index}
                  className={`flex items-center p-4 rounded shadow ${
                    achievement.locked ? 'bg-gray-200 text-gray-500' : 'bg-green-100 text-gray-700'
                  }`}
                >
                  <span>{achievement.description}</span>
                  <span className="ml-auto">
                    {achievement.locked ? (
                      <span className="text-sm font-medium">Locked</span>
                    ) : (
                      <span className="text-sm font-medium">+{achievement.xp} XP</span>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentYourHive;
