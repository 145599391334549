// src/components/Students/apps/StudentWhiteboard.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary
import { Excalidraw } from '@excalidraw/excalidraw';
import { rtdb } from '../../../firebase';
import { ref as dbRef, onValue } from 'firebase/database';

const StudentWhiteboard = ({ classId }) => {
  const [whiteboardData, setWhiteboardData] = useState(null);
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');

  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
    return `${weekday} the ${day} of ${month} ${formattedTime}`;
  };

  useEffect(() => {
    // Update the formatted date every minute
    const interval = setInterval(() => {
      setFormattedDate(getFormattedDate());
    }, 60000); // 60,000 ms = 1 minute

    // Initialize the formatted date
    setFormattedDate(getFormattedDate());

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!classId) {
      console.error('Class ID is not provided to StudentWhiteboard.');
      return;
    }

    const whiteboardPath = `activeclasses/${classId}/whiteboard/Teacher`;
    const whiteboardRef = dbRef(rtdb, whiteboardPath);

    const unsubscribe = onValue(
      whiteboardRef,
      (snapshot) => {
        const data = snapshot.val();
        console.log('Fetched Whiteboard Data:', data);
        if (data) {
          const { appState, elements } = data;
          const { viewModeEnabled, ...restAppState } = appState || {};

          const newWhiteboardData = {
            elements: elements || [],
            appState: restAppState,
          };
          console.log('Setting Whiteboard Data:', newWhiteboardData);
          setWhiteboardData(newWhiteboardData);
        } else {
          console.warn('No whiteboard data found at path:', whiteboardPath);
          setWhiteboardData(null);
        }
      },
      (error) => {
        console.error('Error fetching whiteboard data:', error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [classId]);

  const isValidElement = (element) => {
    return (
      element.id &&
      typeof element.id === 'string' &&
      element.type &&
      typeof element.type === 'string' &&
      typeof element.x === 'number' &&
      typeof element.y === 'number' &&
      typeof element.width === 'number' &&
      typeof element.height === 'number'
      // Add more property checks as needed
    );
  };

  useEffect(() => {
    if (whiteboardData && excalidrawAPI) {
      const allElementsValid = whiteboardData.elements.every(isValidElement);
      if (allElementsValid) {
        console.log('All elements valid. Replacing scene.');
        try {
          excalidrawAPI.replaceScene({
            elements: whiteboardData.elements,
            appState: {
              ...whiteboardData.appState,
              viewModeEnabled: true,
            },
          });
          console.log('Scene replaced successfully.');
        } catch (error) {
          console.error('Error replacing scene:', error);
        }
      } else {
        console.error('Invalid elements detected. Scene not updated.');
      }
    }
  }, [whiteboardData, excalidrawAPI]);

  return (
    <div className="flex flex-col w-full h-screen bg-white student-whiteboard">
      
      {/* Header Section */}
      <header className="flex-none bg-white shadow-md">
        {/* Title and Date Section */}
        <div className="w-full px-4 py-4">
          <div className="max-w-5xl mx-auto">
            {/* Title */}
            <p className="text-3xl font-bold text-center">Whiteboard</p>
            {/* Current Date and Time */}
            <div className="mt-2 text-xs font-bold text-center text-gray-600">
              {formattedDate}
            </div>
          </div>
        </div>

        {/* Banner Area with Enhanced Shadow */}
        <div className="w-full bg-white shadow-md">
          <img
            src={PuzzleBanner}
            alt="Puzzle Banner"
            className="object-cover w-full"
            style={{ height: '96px' }}
          />
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-grow">
        {whiteboardData ? (
          <Excalidraw
            key={whiteboardData ? whiteboardData.elements.length : 'initial'}
            initialData={whiteboardData}
            theme="light"
            viewModeEnabled={true} // Set to true for read-only
            zenModeEnabled={false}
            gridModeEnabled={false}
            UIOptions={{
              canvasActions: {
                loadScene: false,
                saveToActiveFile: false,
                export: false,
                saveAsImage: false,
                toggleTheme: false,
              },
              toolbar: false,
              footer: false,
            }}
            onExcalidrawReady={(api) => {
              console.log('Excalidraw API is ready:', api);
              setExcalidrawAPI(api);
            }}
            style={{ backgroundColor: '#ffffff', height: '100%', width: '100%' }}
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <p className="text-lg text-gray-700">Loading Whiteboard...</p>
          </div>
        )}
      </div>
    </div>
  );
};

StudentWhiteboard.propTypes = {
  classId: PropTypes.string.isRequired,
};

export default StudentWhiteboard;
