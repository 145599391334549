// src/components/Teachers/Tests/Scene.jsx

import React, { useEffect, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import FirstPersonControls from './FirstPersonControls';
import PropTypes from 'prop-types';

const Scene = ({
  blocks,
  addBlock,
  removeBlock,
}) => {
  // Access the Three.js raycaster and camera
  const { raycaster, camera, scene } = useThree();

  // Function to perform raycasting from the center of the screen
  const performRaycast = useCallback(() => {
    // Define the center of the screen in Normalized Device Coordinates (NDC)
    const center = new THREE.Vector2(0, 0); // (0,0) is the center in NDC

    // Set the raycaster from the camera through the center
    raycaster.setFromCamera(center, camera);

    // Define objects to intersect: ground and all blocks
    const objectsToIntersect = [];

    // Ground is a plane named "Ground"
    const ground = scene.getObjectByName('Ground');
    if (ground) objectsToIntersect.push(ground);

    // Add all block meshes
    blocks.forEach(block => {
      const blockMesh = scene.getObjectByName(block.id);
      if (blockMesh) objectsToIntersect.push(blockMesh);
    });

    // Perform the intersection test
    const intersects = raycaster.intersectObjects(objectsToIntersect, false);

    if (intersects.length > 0) {
      return intersects[0];
    }

    return null;
  }, [raycaster, camera, blocks, scene]);

  // Handler for mouse clicks
  const handleMouseClick = useCallback((event) => {
    event.preventDefault();

    // Determine the type of click: left (0) or right (2)
    const isLeftClick = event.button === 0;
    const isRightClick = event.button === 2;

    // Perform raycast from center
    const intersection = performRaycast();

    if (intersection) {
      const point = intersection.point;
      const object = intersection.object;

      if (object.name === 'Ground') {
        // Clicked on the ground; add a block at the clicked position
        const [x, z] = [
          Math.round(point.x),
          Math.round(point.z),
        ];
        addBlock(x, 0, z);
      } else {
        // Clicked on a block
        const { position } = object.userData; // Assuming you store position in userData
        if (isLeftClick) {
          // Left-click to add a block adjacent to the clicked face
          const normal = intersection.face.normal;
          const [x, y, z] = position;
          const newPos = [
            Math.round(x + normal.x),
            Math.round(y + normal.y),
            Math.round(z + normal.z),
          ];
          addBlock(newPos[0], newPos[1], newPos[2]);
        } else if (isRightClick) {
          // Right-click to remove the block
          removeBlock(position);
        }
      }
    }
  }, [performRaycast, addBlock, removeBlock]);

  // Add event listeners for mouse clicks
  useEffect(() => {
    // Add event listeners to the window
    window.addEventListener('mousedown', handleMouseClick);
    window.addEventListener('contextmenu', (e) => e.preventDefault()); // Prevent context menu on right-click

    return () => {
      // Clean up event listeners on unmount
      window.removeEventListener('mousedown', handleMouseClick);
    };
  }, [handleMouseClick]);

  return (
    <>
      {/* Lighting */}
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[10, 20, 10]}
        intensity={1}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-bias={-0.00001}
        shadow-normalBias={0.001}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
        shadow-camera-near={0.1}
        shadow-camera-far={100}
      />

      {/* Controls */}
      <FirstPersonControls />

      {/* Ground Plane */}
      <mesh
        name="Ground"
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, 0, 0]}
        receiveShadow
      >
        <planeGeometry args={[100, 100]} />
        <meshStandardMaterial color="#98fb98" />
      </mesh>

      {/* Render Blocks */}
      {blocks.map((block) => (
        <mesh
          key={block.id}
          name={block.id} // Assign unique name for identification
          position={[
            block.position[0],
            block.position[1] + 0.5, // Adjust y-position to sit on the ground
            block.position[2],
          ]}
          castShadow
          receiveShadow
          userData={{ position: block.position }} // Store position for easy access
        >
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={block.color} />
        </mesh>
      ))}
    </>
  );
};

// Define PropTypes for better type checking
Scene.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      position: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  addBlock: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired,
};

export default Scene;
