// src/components/Teachers/Workbook/TeacherWorkbook.js

import React, { useState, useEffect } from 'react';
import { rtdb } from '../../../firebase'; // Ensure rtdb is correctly exported
import { ref as dbRef, onValue, off, update } from 'firebase/database'; // Added 'update' for XP increment
import { useAuth } from '../../../contexts/AuthContext';
import PropTypes from 'prop-types';
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported

// Import banner image similar to Whiteboard
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

const TeacherWorkbook = ({ onClose, classID }) => {
  const { currentUser } = useAuth();
  const [bees, setBees] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize as true
  const [error, setError] = useState(null);
  const [selectedBee, setSelectedBee] = useState(null); // For modal view

  console.log('TeacherWorkbook rendered.');
  console.log('Received classID:', classID); // Debug log
  console.log('Current User:', currentUser); // Debug log

  // Fetch bees' names from RTDB where logged is "yes"
  useEffect(() => {
    if (!classID) {
      console.error('Missing classID.');
      setError('Invalid class ID.');
      setLoading(false);
      return;
    }

    const beesRef = dbRef(rtdb, `activeclasses/${classID}/beeNames`);
    console.log('Listening to RTDB path:', `activeclasses/${classID}/beeNames`); // Debug log

    const handleData = (snapshot) => {
      const data = snapshot.val();
      console.log('Fetched data from RTDB:', data); // Debug log

      if (data) {
        console.log(`Data retrieved for classID ${classID}:`, data);
        const beesArray = Object.keys(data)
          .filter((name) => {
            const isLogged = data[name].logged === 'yes'; // Ensure case matches
            if (!isLogged) {
              console.log(`Bee "${name}" is not logged.`);
            }
            return isLogged;
          })
          .map((name) => ({
            name: name.trim(),
            totalxp: data[name].totalxp || 0,
            classId: classID,
          }));
        console.log('Parsed bees array:', beesArray); // Debug log
        setBees(beesArray);
      } else {
        console.warn(`No data found at path activeclasses/${classID}/beeNames.`);
        setBees([]);
      }
      setLoading(false);
    };

    onValue(beesRef, handleData, (err) => {
      console.error('Error fetching bees:', err);
      setError('Failed to load bees. Please try again.');
      setLoading(false);
    });

    console.log(`Listener attached to activeclasses/${classID}/beeNames`);

    // Cleanup listener on unmount
    return () => {
      off(beesRef, 'value', handleData);
      console.log(`Detached listener from activeclasses/${classID}/beeNames`);
    };
  }, [classID]);

  // Function to handle XP increment
  const handleAddXp = (beeName) => {
    const beeIndex = bees.findIndex((bee) => bee.name === beeName);
    if (beeIndex !== -1) {
      const updatedBees = [...bees];
      updatedBees[beeIndex].totalxp += 1;
      setBees(updatedBees);

      // Update in RTDB
      const beeRef = dbRef(rtdb, `activeclasses/${classID}/beeNames/${beeName}/totalxp`);
      update(beeRef, updatedBees[beeIndex].totalxp)
        .then(() => {
          console.log(`XP updated for ${beeName}`);
        })
        .catch((err) => {
          console.error('Error updating XP:', err);
        });
    }
  };

  // JSX for a single bee's card
  const BeeCard = ({ bee }) => {
    console.log('Rendering BeeCard for:', bee.name); // Debug log

    return (
      <div className="flex flex-col items-center">
        {/* A4 Page Shape */}
        <div
          className="relative transition-transform bg-white rounded-lg shadow-md cursor-pointer hover:scale-105"
          style={{
            width: '150px', // Adjusted width for screen
            height: '212px', // Height maintaining A4 aspect ratio (150 * 297 / 210 ≈ 212)
            maxWidth: '150px',
            maxHeight: '212px',
          }}
          onClick={() => setSelectedBee(bee)}
        >
          {/* Placeholder for typing content */}
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-400">Typing Area</p>
          </div>

          {/* XP Button */}
          <button
            className="absolute p-1 text-lg transition-opacity bg-blue-500 rounded-full shadow opacity-0 bottom-2 right-2 hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the card click
              handleAddXp(bee.name);
            }}
            aria-label="Add XP"
            title="Add XP"
          >
            ❤️
          </button>
        </div>

        {/* Bee Name */}
        <div className="mt-2 text-sm font-medium text-center text-gray-700">
          {bee.name}
        </div>
      </div>
    );
  };

  BeeCard.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
  };

  // List of bee cards with adjusted grid layout
  const beeList = (
    <div className="grid justify-center grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {bees.map((bee) => (
        <BeeCard key={`${bee.classId}-${bee.name}`} bee={bee} />
      ))}
    </div>
  );

  // Modal view for selected bee
  const BeeModal = ({ bee, onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
        <div
          className="relative bg-white rounded-lg shadow-xl"
          style={{
            width: '90%',
            height: '80%',
            maxWidth: '800px',
            maxHeight: '1100px',
            overflow: 'hidden', // Remove scrollbars
          }}
          onClick={onClose}
        >
          <button
            onClick={onClose}
            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
            aria-label="Close Modal"
            onMouseDown={(e) => e.stopPropagation()} // Prevent closing when clicking the button
          >
            <FaTimes />
          </button>
          <div className="flex flex-col h-full p-12">
            {/* Enlarge the BeeCard inside the modal */}
            <div className="flex items-center justify-center flex-grow">
              <div
                className="relative bg-white rounded-lg shadow-md cursor-default"
                style={{
                  width: '100%', // Make it responsive within the modal
                  height: 'auto',
                  paddingTop: '141.42%', // 210/297 ≈ 0.707, so padding-top: 70.7% to maintain aspect ratio
                  maxWidth: '800px',
                }}
              >
                <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-between h-full p-8">
                  <h2 className="mb-4 text-2xl font-bold text-center">{bee.name}</h2>
                  {/* Additional detailed content can be added here */}
                  <div className="mt-4 text-center">
                    {/* Placeholder for detailed typing content */}
                    <p className="text-gray-400">Detailed Typing Area (To be implemented)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  BeeModal.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {/* Header Section with SVG Banner and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
        {/* Title */}
        <h1 className="text-3xl font-bold text-center">Teacher Workbook</h1>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {new Date().toLocaleString()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Enhanced shadow retained
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Workbook"
      >
        <FaTimes />
      </button>

      {/* Main Content */}
      <div className="flex-grow p-6 mt-4 overflow-auto bg-gray-100">
        {loading && <p>Loading bees...</p>}
        {!loading && error && <p className="text-red-500">{error}</p>}
        {!loading && !error && bees.length === 0 && (
          <p className="text-gray-500">No active bees found.</p>
        )}
        {!loading && !error && bees.length > 0 && beeList}

        {/* Modal View */}
        {selectedBee && <BeeModal bee={selectedBee} onClose={() => setSelectedBee(null)} />}
      </div>
    </div>
  );
};

// PropTypes for validation
TeacherWorkbook.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
};

export default TeacherWorkbook;
