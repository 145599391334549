// src/components/Teachers/Tests/VoxelGame.jsx

import React, { useEffect } from 'react';
import VoxelEditor from './VoxelEditor';

const VoxelGame = () => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      console.log('Context menu opened and prevented.');
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <VoxelEditor />
    </div>
  );
};

export default VoxelGame;
