// src/components/Teachers/Toolbar.js

import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Modal from '../../common/modal'; // Ensure correct import path

const Toolbar = ({
  isVisible,
  toggleToolbar,
  showNames,
  toggleShowNames,
  showActiveApps,
  toggleShowActiveApps,
  showOnlyOnline,
  toggleShowOnlyOnline,
  onToggleTimer,
  timerActive, // Received prop for Timer active state
  pollActive, // Placeholder for Poll active state
  togglePoll, // Placeholder for toggling Poll
  calendarActive, // Placeholder for Calendar active state
  toggleCalendar, // Placeholder for toggling Calendar
}) => {
  return (
    <>
      {/* Toolbar Container */}
      <div
        className={`fixed top-1/2 right-0 transform -translate-y-1/2 transition-transform duration-500 bg-white shadow-lg bg-opacity-90 backdrop-filter backdrop-blur-lg flex flex-col space-y-2 p-4 rounded-l-2xl ${
          isVisible ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{ zIndex: 61 }} // Ensures toolbar overlays other components
      >

        {/* Names Toggle Button */}
        <button
          onClick={toggleShowNames}
          className={`w-24 h-12 px-2 py-1 text-center text-white rounded-full transition-colors duration-300 ${
            showNames
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-gray-500 bg-opacity-50 hover:bg-gray-400'
          }`}
          aria-pressed={showNames}
        >
          Names
        </button>

        {/* Active Apps Toggle Button */}
        <button
          onClick={toggleShowActiveApps}
          className={`w-24 h-12 px-2 py-1 text-center text-white rounded-full transition-colors duration-300 ${
            showActiveApps
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-gray-500 bg-opacity-50 hover:bg-gray-400'
          }`}
          aria-pressed={showActiveApps}
        >
          Apps
        </button>

        {/* Online/All Toggle Button */}
        <button
          onClick={toggleShowOnlyOnline}
          className={`w-24 h-12 px-2 py-1 text-center text-white rounded-full transition-colors duration-300 ${
            showOnlyOnline
              ? 'bg-gray-500 bg-opacity-50 hover:bg-gray-400'
              : 'bg-blue-500 hover:bg-blue-600'
          }`}
          aria-pressed={showOnlyOnline}
        >
          {showOnlyOnline ? 'Active' : 'All'}
        </button>

        {/* Timer Toggle Button */}
        <button
          onClick={onToggleTimer}
          className={`w-24 h-12 px-2 py-1 text-center text-white rounded-full transition-colors duration-300 ${
            timerActive
              ? 'bg-blue-500 hover:bg-blue-600' // Blue when active
              : 'bg-gray-500 bg-opacity-50 hover:bg-gray-400' // Grey when inactive
          }`}
          aria-pressed={timerActive}
        >
          Timer
        </button>

        {/* Poll Toggle Button (Left Grey) */}
        <button
          onClick={togglePoll}
          className={`w-24 h-12 px-2 py-1 text-center text-white rounded-full transition-colors duration-300 ${
            pollActive
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-gray-500 bg-opacity-50 hover:bg-gray-400'
          }`}
          aria-pressed={pollActive}
          disabled // Disable until implemented
          title="Poll (Coming Soon)"
        >
          Poll
        </button>

     </div>

      {/* Toggle Button */}
      <button
        onClick={toggleToolbar}
        className={`fixed top-1/2 transform -translate-y-1/2 p-1 text-blue-500 transition-all duration-500 bg-white rounded-l-2xl shadow-lg`}
        style={{
          right: isVisible ? '110px' : '0px', // Adjusted to accommodate toolbar width
          zIndex: 60, // Ensure toggle button is below the toolbar
        }}
        aria-label={isVisible ? 'Close Toolbar' : 'Open Toolbar'}
        aria-expanded={isVisible}
      >
        {isVisible ? <ChevronRight size={40} /> : <ChevronLeft size={40} />}
      </button>
    </>
  );
};

export default Toolbar;
