// XPProgressArc Component

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; // Ensure PropTypes is imported
import Beesolo from '../../assets/beesolo.svg'; // Adjust path as needed

// Constants and utility functions
const MAX_LEVEL = 250;
const BASE_XP = 100;
const XP_INCREMENT_RATIO = 1.1;
const NUM_BEES = 3; // Number of bees

const computeXpPerLevel = () => {
  const xpPerLevel = [BASE_XP];
  for (let i = 1; i < MAX_LEVEL; i++) {
    const nextXp = Math.round(xpPerLevel[i - 1] * XP_INCREMENT_RATIO);
    xpPerLevel.push(nextXp);
  }
  return xpPerLevel;
};

const xpPerLevel = computeXpPerLevel();

const XPProgressArc = ({ averageXP }) => {
  const [averageLevel, setAverageLevel] = useState(0);
  const [averageCurrentXP, setAverageCurrentXP] = useState(0);
  const [displayXP, setDisplayXP] = useState(0);
  const [canLevelUp, setCanLevelUp] = useState(false);
  const [flippedStates, setFlippedStates] = useState(
    Array(NUM_BEES).fill(false)
  ); // For three bees
  const [beePositions, setBeePositions] = useState(
    Array(NUM_BEES).fill({ x: 0, y: 0 })
  ); // Positions for bees
  const animationRef = useRef(null);
  const levelUpTimeoutRef = useRef(null);
  const movementTimeoutsRef = useRef([]); // To store movement timeouts

  // Compute average level based on average XP
  useEffect(() => {
    let accumulatedXP = 0;
    let level = 0;
    while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= averageXP) {
      accumulatedXP += xpPerLevel[level];
      level++;
    }
    setAverageLevel(level);
    setAverageCurrentXP(averageXP - accumulatedXP);
  }, [averageXP]);

  // Current XP required for next level
  const currentXPRequired =
    averageLevel < MAX_LEVEL ? xpPerLevel[averageLevel] : xpPerLevel[MAX_LEVEL - 1];

  // Calculate progress percentage based on displayXP
  const progress = Math.min(displayXP / currentXPRequired, 1);

  // Arc parameters
  const radius = 80;
  const strokeWidth = 10;
  const center = 100;

  // Calculate the SVG path for the arc
  const getArcPath = (percentage) => {
    const angleStart = 180;
    const angleEnd = 180 + 180 * percentage;

    const startRad = (angleStart * Math.PI) / 180;
    const endRad = (angleEnd * Math.PI) / 180;

    const startX = center + radius * Math.cos(startRad);
    const startY = center + radius * Math.sin(startRad);
    const endX = center + radius * Math.cos(endRad);
    const endY = center + radius * Math.sin(endRad);

    const sweepAngle = 180 * percentage;
    const largeArcFlag = sweepAngle > 180 ? 1 : 0;
    const sweepFlag = 1;

    return `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;
  };

  // Animation Effect for Smooth XP Transition
  useEffect(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    const duration = 2000; // Duration of the animation in ms
    const frameRate = 60; // Frames per second
    const totalFrames = Math.round((duration / 1000) * frameRate);
    let frame = 0;

    const startXP = displayXP;
    const endXP = Math.min(averageCurrentXP, currentXPRequired);
    const deltaXP = endXP - startXP;

    const easeInOutCubic = (t) =>
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    const animate = () => {
      frame++;
      const progressFraction = frame / totalFrames;
      const easeProgress = easeInOutCubic(progressFraction);
      const newDisplayXP = Math.round(startXP + deltaXP * easeProgress);

      setDisplayXP(newDisplayXP);

      if (frame < totalFrames) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setDisplayXP(endXP);
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationRef.current);
  }, [averageCurrentXP, currentXPRequired]);

  // Update canLevelUp state when XP reaches 100%
  useEffect(() => {
    setCanLevelUp(averageCurrentXP >= currentXPRequired && averageLevel < MAX_LEVEL);
  }, [averageCurrentXP, currentXPRequired, averageLevel]);

  // Auto Level-Up Effect
  useEffect(() => {
    if (canLevelUp) {
      // Set a timeout to auto level up after 2000ms
      levelUpTimeoutRef.current = setTimeout(() => {
        handleLevelUp();
      }, 2000);
    }

    // Cleanup the timeout if canLevelUp changes before timeout completes
    return () => {
      if (levelUpTimeoutRef.current) {
        clearTimeout(levelUpTimeoutRef.current);
      }
    };
  }, [canLevelUp]);

  const handleLevelUp = () => {
    if (averageLevel >= MAX_LEVEL || !canLevelUp) return;

    // Implement any level-up related logic here.
    console.log('Average XP reached for level up.');
  };

  // Floating Animation Logic for Bees with Smooth Random Movement
  useEffect(() => {
    const maxOffset = 20; // Increased from 10 to 20 for more spread

    const moveBee = (index) => {
      setBeePositions((prevPositions) => {
        const newPositions = [...prevPositions];
        const deltaX = (Math.random() - 0.5) * maxOffset;
        const deltaY = (Math.random() - 0.5) * maxOffset;
        // Constrain positions within -maxOffset to +maxOffset
        newPositions[index] = {
          x: Math.max(-maxOffset, Math.min(maxOffset, newPositions[index].x + deltaX)),
          y: Math.max(-maxOffset, Math.min(maxOffset, newPositions[index].y + deltaY)),
        };
        return newPositions;
      });

      // Schedule next movement with random interval between 2s to 5s
      const nextMoveIn = 2000 + Math.random() * 3000;
      movementTimeoutsRef.current[index] = setTimeout(() => moveBee(index), nextMoveIn);
    };

    // Initialize movement for each bee
    for (let i = 0; i < NUM_BEES; i++) {
      moveBee(i);
    }

    // Cleanup on unmount
    return () => {
      movementTimeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  // Floating Animation Logic for Bees' Flipping States
  useEffect(() => {
    const moveBee = (index) => {
      const duration = 6000 + Math.random() * 4000; // Random duration between 6s and 10s

      setTimeout(() => {
        setFlippedStates((prev) => {
          const newFlipped = [...prev];
          newFlipped[index] = Math.random() > 0.5;
          return newFlipped;
        });
        moveBee(index); // Recursive call for the next flip
      }, duration);
    };

    for (let i = 0; i < NUM_BEES; i++) {
      moveBee(i);
    }

    // Cleanup is handled automatically as `setTimeout` does not need to be cleared
  }, []);

  return (
    <div className="relative flex flex-col items-center gap-0 p-6 pointer-events-none">
      {/* Embedded CSS for Animations */}
      <style jsx>{`
        .bee {
          will-change: transform;
          transition: transform 1s;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(${({ x, y }) => x}px, ${({ x, y }) => y}px) scaleX(${({ flipped }) => (flipped ? -1 : 1)});
        }
      `}</style>

      {/* Main Container has pointer-events: none to allow clicks to pass through */}
      <div className="relative">
        <div className="w-20 h-20 bg-gray-100 rounded-full" />

        {/* Adjusted SVG Positioning */}
        <svg
          className="absolute z-30 w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          viewBox="0 0 200 200"
        >
          <path
            d={getArcPath(1)}
            fill="none"
            stroke="#e5e7eb"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          <path
            d={getArcPath(progress)}
            fill="none"
            stroke="#4f46e5"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </svg>

        {/* Floating Bees */}
        <div className="absolute z-40 w-20 h-20 transform -translate-x-10 -translate-y-10 top-1/2 left-1/2">
          {beePositions.map((position, index) => (
            <img
              key={index}
              src={Beesolo}
              alt={`Floating Bee ${index + 1}`}
              className="bee"
              style={{
                width: '1rem', // 3X smaller assuming original is w-6 (1.5rem)
                height: '1rem',
                transition: 'transform 3s',
                transform: `translate(${position.x}px, ${position.y}px) scaleX(${flippedStates[index] ? -1 : 1})`,
              }}
              aria-hidden="true"
            />
          ))}
        </div>

        {/* Updated Hive and Level Text */}
        <div className="absolute z-20 text-center transform -translate-x-1/2 -translate-y-0 top-10 left-1/2">
          <div className="text-xs font-bold text-gray-800">Hive</div>
          <div className="text-[10px] font-bold text-gray-800 whitespace-nowrap">
            Level: {averageLevel}
          </div>
        </div>
      </div>

      {/* Level Up Indicator */}
      <div className="mt-2">
        {canLevelUp && (
          <span className="text-sm font-bold text-green-500">
            Average Level Up!
          </span>
        )}
      </div>
    </div>
  );
};

XPProgressArc.propTypes = {
  averageXP: PropTypes.number.isRequired, // Ensure PropTypes is defined
};

export default XPProgressArc;