// src/components/Students/XPProgressArc.js

import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { rtdb } from '../../../firebase'; // Adjust the import path if necessary
import { useStudentManager } from '../../../contexts/Studentmanager'; // Import useStudentManager
import Beesolo from '../../assets/beesolo.svg'; // Importing the Beesolo SVG

// Constants
const MAX_LEVEL = 250;
const BASE_XP = 100;
const XP_INCREMENT_RATIO = 1.1; // 30% increase per level

// Precompute XP required for each level up to MAX_LEVEL
const computeXpPerLevel = () => {
  const xpPerLevel = [BASE_XP];
  for (let i = 1; i < MAX_LEVEL; i++) {
    const nextXp = Math.round(xpPerLevel[i - 1] * XP_INCREMENT_RATIO);
    xpPerLevel.push(nextXp);
  }
  return xpPerLevel;
};

const xpPerLevel = computeXpPerLevel();

const XPProgressArc = () => {
  const { studentInfo } = useStudentManager();
  const { classId, studentName } = studentInfo || {};
  const [totalXP, setTotalXP] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentXP, setCurrentXP] = useState(0);
  const [displayXP, setDisplayXP] = useState(0);
  const [canLevelUp, setCanLevelUp] = useState(false);
  const animationRef = useRef(null);
  const levelUpTimeoutRef = useRef(null); // Ref to store the timeout ID

  // Fetch totalXP from RTDB
  useEffect(() => {
    if (!classId || !studentName) return;

    const xpRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}/totalxp`);

    const handleValueChange = (snapshot) => {
      const xp = snapshot.val() || 0;
      setTotalXP(xp);
    };

    onValue(xpRef, handleValueChange);

    // Cleanup listener on unmount
    return () => {
      off(xpRef, 'value', handleValueChange);
    };
  }, [classId, studentName]);

  // Compute current level and current XP based on totalXP
  useEffect(() => {
    let accumulatedXP = 0;
    let level = 0;
    while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= totalXP) {
      accumulatedXP += xpPerLevel[level];
      level++;
    }
    setCurrentLevel(level);
    setCurrentXP(totalXP - accumulatedXP);
  }, [totalXP]);

  // Current XP required for next level
  const currentXPRequired =
    currentLevel < MAX_LEVEL ? xpPerLevel[currentLevel] : xpPerLevel[MAX_LEVEL - 1];

  // Calculate progress percentage based on displayXP
  const progress = Math.min(displayXP / currentXPRequired, 1);

  // Arc parameters
  const radius = 80; // Reduced radius to prevent overflow
  const strokeWidth = 10; // Adjusted stroke width
  const center = 100;

  // Calculate the SVG path for the arc
  const getArcPath = (percentage) => {
    const angleStart = 180;
    const angleEnd = 180 + 180 * percentage;

    const startRad = (angleStart * Math.PI) / 180;
    const endRad = (angleEnd * Math.PI) / 180;

    const startX = center + radius * Math.cos(startRad);
    const startY = center + radius * Math.sin(startRad);
    const endX = center + radius * Math.cos(endRad);
    const endY = center + radius * Math.sin(endRad);

    const sweepAngle = 180 * percentage;
    const largeArcFlag = sweepAngle > 180 ? 1 : 0;
    const sweepFlag = 1;

    return `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;
  };

  // Animation Effect for Smooth XP Transition
  useEffect(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    const duration = 2000; // Duration of the animation in ms
    const frameRate = 60; // Frames per second
    const totalFrames = Math.round((duration / 1000) * frameRate);
    let frame = 0;

    const startXP = displayXP;
    const endXP = Math.min(currentXP, currentXPRequired);
    const deltaXP = endXP - startXP;

    const easeInOutCubic = (t) =>
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    const animate = () => {
      frame++;
      const progressFraction = frame / totalFrames;
      const easeProgress = easeInOutCubic(progressFraction);
      const newDisplayXP = Math.round(startXP + deltaXP * easeProgress);

      setDisplayXP(newDisplayXP);

      if (frame < totalFrames) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setDisplayXP(endXP);
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationRef.current);
  }, [currentXP, currentXPRequired]);

  // Update canLevelUp state when XP reaches 100%
  useEffect(() => {
    setCanLevelUp(currentXP >= currentXPRequired && currentLevel < MAX_LEVEL);
  }, [currentXP, currentXPRequired, currentLevel]);

  // Auto Level-Up Effect
  useEffect(() => {
    if (canLevelUp) {
      // Set a timeout to auto level up after 2000ms
      levelUpTimeoutRef.current = setTimeout(() => {
        handleLevelUp();
      }, 2000);
    }

    // Cleanup the timeout if canLevelUp changes before timeout completes
    return () => {
      if (levelUpTimeoutRef.current) {
        clearTimeout(levelUpTimeoutRef.current);
      }
    };
  }, [canLevelUp]);

  const handleLevelUp = () => {
    if (currentLevel >= MAX_LEVEL || !canLevelUp) return;

    // Update totalXP to reflect leveling up
    // Note: This assumes that leveling up is managed elsewhere.
    // If you need to update totalXP here, you'll have to write to RTDB.
    // For example:
    /*
    const newTotalXP = totalXP - currentXPRequired;
    const xpRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}/totalxp`);
    set(xpRef, newTotalXP);
    */
    // Since the user requested to reflect RTDB's value, we won't modify RTDB here.

    // Optionally, you can trigger any level-up animations or notifications here.
  };

  return (
    <div className="relative flex flex-col items-center gap-0 p-6 pointer-events-none">
      {/* Main Container has pointer-events: none to allow clicks to pass through */}
      <div className="relative">
        <div className="w-20 h-20 bg-gray-100 rounded-full" />

        {/* Adjusted SVG Positioning */}
        <svg
          className="absolute z-30 w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          viewBox="0 0 200 200"
        >
          <path
            d={getArcPath(1)}
            fill="none"
            stroke="#e5e7eb"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          <path
            d={getArcPath(progress)}
            fill="none"
            stroke="#4f46e5"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </svg>

        {/* Beesolo SVG in the Center */}
        <img
          src={Beesolo}
          alt="Beesolo"
          className="absolute z-40 w-6 h-6 transform -translate-x-1/2 -scale-x-100 -translate-y-3/4 top-1/2 left-1/2"
        />

        <div className="absolute z-20 text-center transform -translate-x-1/2 -translate-y-0 top-12 left-1/2">
          <div className="text-xs font-bold text-gray-800">Lvl {currentLevel}</div>
        </div>
      </div>

      {/* Level Up Button */}
      <button
        aria-disabled={!canLevelUp}
        disabled={true} // Always disabled to prevent user clicks
        className={`mt-[-1rem] px-2 py-0 rounded-lg transition-colors ${
          canLevelUp
            ? 'bg-blue-500 text-white'
            : 'bg-gray-100 text-gray-700'
        } font-semibold min-w-[50px] cursor-not-allowed z-20`}
      >
        {canLevelUp ? (
          <span className="text-xs font-bold">Level Up!</span>
        ) : (
          <span className="text-xs font-bold">{`${displayXP} / ${currentXPRequired}`}</span>
        )}
      </button>
    </div>
  );
};

export default XPProgressArc;
