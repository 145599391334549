import React from 'react';
import { ReactComponent as FaTrash } from './FaTrash.svg';
import { ReactComponent as FaChartBar } from './FaChartBar.svg';
import { ReactComponent as MdScience } from './MdScience.svg';
import { ReactComponent as FaBook } from './FaBook.svg';
import { ReactComponent as IoIosPeople } from './IoIosPeople.svg';
import { ReactComponent as FaPaintBrush } from './FaPaintBrush.svg';
import { ReactComponent as GiAbstract050 } from './GiAbstract050.svg';
import { ReactComponent as FaLeaf } from './FaLeaf.svg';
import { ReactComponent as GiBrain } from './GiBrain.svg';
import { ReactComponent as FaAtom } from './FaAtom.svg';
import { ReactComponent as FaLanguage } from './FaLanguage.svg';
import { ReactComponent as MdSportsBasketball } from './MdSportsBasketball.svg';
import { ReactComponent as FaFolder } from './FaFolder.svg';
import { ReactComponent as FaPlus } from './FaPlus.svg';
import { ReactComponent as FaTimes } from './FaTimes.svg';
import { ReactComponent as FaCheck } from './FaCheck.svg';
import { ReactComponent as FaSave } from './FaSave.svg';
import { ReactComponent as FaChevronLeft } from './FaChevronLeft.svg';
import { ReactComponent as FaChevronRight } from './FaChevronRight.svg';
import { ReactComponent as FaEye } from './FaEye.svg';
import { ReactComponent as FaChalkboardTeacher } from './FaChalkboardTeacher.svg';
import { ReactComponent as FaThLarge } from './FaThLarge.svg';
import { ReactComponent as FaList } from './FaList.svg';

// Higher-Order Component to apply default size and color
const withDefaultStyle = (
  IconComponent,
  defaultSize = 'w-4 h-4',
  defaultColor = 'text-gray-500'
) => {
  return ({ className = '', ...props }) => (
    <IconComponent
      className={`${className} ${defaultSize} ${defaultColor}`}
      {...props}
    />
  );
};

// Wrapped Icons with Default Size and Grey Color
const FaTrashWrapped = withDefaultStyle(FaTrash);
const FaChartBarWrapped = withDefaultStyle(FaChartBar);
const MdScienceWrapped = withDefaultStyle(MdScience);
const FaBookWrapped = withDefaultStyle(FaBook);
const IoIosPeopleWrapped = withDefaultStyle(IoIosPeople);
const FaPaintBrushWrapped = withDefaultStyle(FaPaintBrush);
const GiAbstract050Wrapped = withDefaultStyle(GiAbstract050);
const FaLeafWrapped = withDefaultStyle(FaLeaf);
const GiBrainWrapped = withDefaultStyle(GiBrain);
const FaAtomWrapped = withDefaultStyle(FaAtom);
const FaLanguageWrapped = withDefaultStyle(FaLanguage);
const MdSportsBasketballWrapped = withDefaultStyle(MdSportsBasketball);
const FaFolderWrapped = withDefaultStyle(FaFolder);
const FaPlusWrapped = withDefaultStyle(FaPlus);
const FaTimesWrapped = withDefaultStyle(FaTimes);
const FaCheckWrapped = withDefaultStyle(FaCheck);
const FaSaveWrapped = withDefaultStyle(FaSave);
const FaChevronLeftWrapped = withDefaultStyle(FaChevronLeft);
const FaChevronRightWrapped = withDefaultStyle(FaChevronRight);
const FaEyeWrapped = withDefaultStyle(FaEye);
const FaChalkboardTeacherWrapped = withDefaultStyle(FaChalkboardTeacher);
const FaThLargeWrapped = withDefaultStyle(FaThLarge);
const FaListWrapped = withDefaultStyle(FaList);

// Exporting Wrapped Icons with Original Names
export {
  FaTrashWrapped as FaTrash,
  FaChartBarWrapped as FaChartBar,
  MdScienceWrapped as MdScience,
  FaBookWrapped as FaBook,
  IoIosPeopleWrapped as IoIosPeople,
  FaPaintBrushWrapped as FaPaintBrush,
  GiAbstract050Wrapped as GiAbstract050,
  FaLeafWrapped as FaLeaf,
  GiBrainWrapped as GiBrain,
  FaAtomWrapped as FaAtom,
  FaLanguageWrapped as FaLanguage,
  MdSportsBasketballWrapped as MdSportsBasketball,
  FaFolderWrapped as FaFolder,
  FaPlusWrapped as FaPlus,
  FaTimesWrapped as FaTimes,
  FaCheckWrapped as FaCheck,
  FaSaveWrapped as FaSave,
  FaChevronLeftWrapped as FaChevronLeft,
  FaChevronRightWrapped as FaChevronRight,
  FaEyeWrapped as FaEye,
  FaChalkboardTeacherWrapped as FaChalkboardTeacher,
  FaThLargeWrapped as FaThLarge,
  FaListWrapped as FaList,
};
